import BannerCarousal from "./components/home-page-sections/home-page-banner/banner-carousel";
import OurBenefits from "./components/home-page-sections/home-page-benefit/ourbenefits";
import FranchiseProgram from "./components/home-page-sections/home-page-cta-franchise/ctafranchiseprogram";
import ProcessWorks from "./components/home-page-sections/home-page-process/process-works";
import OurStatistics from "./components/home-page-sections/home-page-our-statistics/ourstatistics";
import CtaApp from "./components/home-page-sections/home-page-cta-app/ctaapp";
import TsQuality from "./components/home-page-sections/home-page-our-partners/tsquality";
import Faqs from "./components/home-page-sections/home-page-faqs/faqs";
import ServiceSelection from "./components/service-selection";
import { useAuthContext } from "../../context/authContext";
import { useGetHomepageContent } from "./homepage-api";
import HomePageSEO from "./homepage-seo";

export default function Homepage() {
  const { getData } = useAuthContext();
  const { isAuth, lang } = getData();
  const { data } = useGetHomepageContent(isAuth, lang);

  return (
    <div>
      <HomePageSEO />
      <BannerCarousal data={data?.image_section ?? []} />
      <ServiceSelection data={data?.service_section} />
      <OurBenefits data={data?.benefits} />
      <FranchiseProgram data={data?.franchise} />
      <ProcessWorks data={data?.process} />
      <OurStatistics data={data?.insights} />
      <CtaApp />
      <TsQuality data={data?.partners} />
      <Faqs data={data?.faqs} />
    </div>
  );
}
