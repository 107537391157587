import { Helmet } from 'react-helmet-async'

const Profile_Data = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Tractor Seva",
  url: "https://tractorseva.com/",
  logo: "https://about.tractorseva.com/wp-content/uploads/2023/06/tractorseva-logo-.svg",
  sameAs: [
    "https://www.facebook.com/tractorsevaindia",
    "https://twitter.com/TractorSeva",
    "https://www.instagram.com/tractorseva/",
    "https://www.linkedin.com/company/tractor-seva/"
  ],
  telephone: "+919209392096",
  address: {
    "@type": "PostalAddress",
    streetAddress: "Bedienung Solution Private Limited",
    addressLocality: "Nagpur",
    addressRegion: "Maharashtra",
    postalCode: "441108",
    addressCountry: "IN"
  }
}

const F_n_Q_DATA = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "How can I change the service appointment date and time?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "You can easily reschedule your service appointment through your Tractor Seva account. Just log in, go to your bookings, and select the option to reschedule. Please do this well in advance to ensure availability."
      }
    },
    {
      "@type": "Question",
      name: "How do I know the status of my service appointment?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Tracking your service appointment is easy. Once booked, you can monitor its booking status & progress through your Tractor Seva account."
      }
    },
    {
      "@type": "Question",
      name: "Is my personal information secure with Tractor Seva?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, we take your privacy seriously. Your personal information is encrypted and stored securely. We only use it for service-related purposes and do not share it with third parties without your consent."
      }
    },
    {
      "@type": "Question",
      name: "Are there any additional charges for pickup and drop-off services?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, if you opt for home service, there will be additional charges associated with the pickup and drop-off services for your convenience. These charges will be clearly communicated to you before confirming your booking, so you can make an informed choice."
      }
    },
    {
      "@type": "Question",
      name: "How do I pay for the service?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "We offer multiple payment options, including online payment through our secure portal."
      }
    },
    {
      "@type": "Question",
      name: "What if I face issues after the service is complete?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "If you encounter any problems post-service, please reach out to our customer support team immediately. We're committed to ensuring your satisfaction, and we'll address any issues promptly. Your peace of mind is our priority."
      }
    },
    {
      "@type": "Question",
      name: "How can I contact customer support if I have questions or concerns?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "You can reach our customer support team through our dedicated helpline number - We are available to assist you and address any questions or concerns you may have regarding your tractor service with Tractor Seva."
      }
    }
  ]
}

export default function HomePageSEO() {

  const host_url = process.env.REACT_APP_HOST_URL;

  return (
    <Helmet>
      <link rel="canonical" href={host_url} />
      <script type="application/ld+json">
        {JSON.stringify(Profile_Data)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(F_n_Q_DATA)}
      </script>
    </Helmet>
  )
}
