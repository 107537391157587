import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useAddBooking } from "./booking-api";
// import { useNavigate } from "react-router-dom";
import {
  dateFormat,
  timeFormat,
} from "../../config/common-function/date-time-format";
import { ISSUES_DATA } from "./booking-form/form-date-time";
import { useRef, useState } from "react";
import { useLanguage } from "../../language/useLanguage";

export default function BookingSummary({ onClose, bookingData, bookingForm }) {
  const addBooking = useAddBooking();
  const langData = useLanguage();
  // const navigate = useNavigate();
  const {
    booking_data,
    customer_address_details,
    customer_tractor_details,
    service_details,
    workshop_details,
    payment_options,
    pricing_details,
  } = bookingData;
  const paymentFormRef = useRef();
  const [paymentGatewayId, setPaymentGatewayId] = useState(
    () => payment_options?.find((p) => p.is_default)?.id ?? 1
  );

  const issueString = Object.entries(JSON.parse(booking_data.issues))
    .map(([issue, text]) => {
      const label = ISSUES_DATA.find((obj) => obj.id === issue)?.label;
      return issue === "other" ? `${label}: ${text}` : label;
    })
    .join(", ");

  function handleSubmit() {
    bookingForm.append("payment_gateway_id", paymentGatewayId);
    //Old Flow --->>>>>>>
    addBooking.mutate(bookingForm, {
      onSuccess: ({ data }) => {
        console.debug({ data });
        const linkData = data.payment_link.split(
          "https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
        );
        window.open(
          `https://www.tractorseva.com/paylink/${linkData[1]}`,
          "_self"
        );
        // submitPaymentForm(data.paymentRequest);
      },
    });
    //Old Flow <<<<<<<----

    // New Flow ---->>>>
    // -> Add Item to Cart
    // -> Submit Order with Cart ID
    // -> Get Payment Request
    // -> Submit Payment Form
    // New Flow <<<<----
  }

  // function submitPaymentForm(paymentData) {
  //   for (const key in paymentData.form) {
  //     paymentFormRef.current.setAttribute(key, paymentData.form[key]);
  //   }
  //   paymentData.inputs.forEach((inputAttr) => {
  //     const input = document.createElement("input");
  //     for (const key in inputAttr) {
  //       input.setAttribute(key, inputAttr[key]);
  //     }
  //     paymentFormRef.current.appendChild(input);
  //   });
  //   paymentFormRef.current.submit();
  // }

  return (
    <div className="booking-summary-container">
      <div className="booking-details">
        <h2>{langData("Booking Details")}</h2>
        <div className="grid">
          <p>{langData("Date & Time")}</p>
          <div>{`${dateFormat(booking_data.booking_date)} | ${timeFormat(
            booking_data.start_time
          )} - ${timeFormat(booking_data.end_time)}`}</div>
          <p>{langData("Service")}</p>
          <div>
            <b>{`${service_details.Item.ServiceType.service_type_name} - ${customer_tractor_details.TractorModel.TractorBrand.brand_name} ${customer_tractor_details.TractorModel.model_name}`}</b>
          </div>
          <p>{langData("Service Preference")}</p>
          <div style={{ textTransform: "capitalize" }}>
            <b>
              {langData(booking_data.service_location)} {langData("Service")}
            </b>
          </div>
          <p>{langData("Customer Address")}</p>
          <div>
            <b>{customer_address_details.address_name}</b>
            <div>{`${customer_address_details.address_1}, ${
              customer_address_details.address_2
                ? customer_address_details.address_2 + ", "
                : ""
            } ${customer_address_details.SubDistrict?.subdistrict_name}, ${
              customer_address_details.SubDistrict?.District?.district_name
            }, ${
              customer_address_details.SubDistrict?.District?.State?.state_name
            } - ${customer_address_details.pincode}`}</div>
          </div>
          <p>{langData("Workshop Address")}</p>
          <div>
            <b>{workshop_details.workshop_name}</b>
            <div>{`${workshop_details.address_1}, ${
              workshop_details.address_2
                ? workshop_details.address_2 + ", "
                : ""
            } ${workshop_details.SubDistrict.subdistrict_name}, ${
              workshop_details.SubDistrict.District.district_name
            }, ${workshop_details.SubDistrict.District.State.state_name} - ${
              workshop_details.pincode
            }`}</div>
          </div>
          <p> {langData("Notes")}</p>
          <div>
            <b>{booking_data.notes}</b>
          </div>
          {issueString && (
            <>
              <p>{langData("Another Issue With Tractor")}</p>
              <div>{langData(issueString)}</div>
            </>
          )}
        </div>
      </div>
      <div className="price-details">
        <h2>{langData("Pricing")}</h2>
        <div className="grid">
          <p>{langData("Basic Service Charges")}</p>
          <div>
            <b>₹ {pricing_details.basic_service_charges}</b>
          </div>
          {Boolean(booking_data.home_service_amount) &&
            booking_data.home_service_amount !== "0" && (
              <>
                <p>{langData("Home Service Charges")}</p>
                <div>
                  <b>₹ {booking_data.home_service_amount}</b>
                </div>
              </>
            )}
          <p>
            {langData("Tax")}({pricing_details.tax_percentage}%)
          </p>
          <div>
            <b>₹ {pricing_details.tax_amount}</b>
          </div>
          <hr />
          <p>{langData("Total")}</p>
          {/* <div style={{ fontSize: 24 }}><b>₹ {formatAmount(formatAmount(service_details.offer_total) + formatAmount(booking_data.home_service_amount))}</b></div> */}
          <div style={{ fontSize: 18 }}>
            <b>₹ {pricing_details.total}</b>
          </div>
          <p>{langData("Coupon")}</p>
          <div style={{ fontSize: 18 }}>
            <b>₹ {pricing_details.coupon}</b>
          </div>
          <p>{langData("Grand Total")}</p>
          <div style={{ fontSize: 24 }}>
            <b>₹ {pricing_details.grand_total}</b>
          </div>
        </div>
      </div>
      <div className="payment-details">
        <h2>{langData("Payment Gateway")}</h2>
        <RadioGroup
          row
          value={paymentGatewayId.toString()}
          onChange={(__event, newValue) => {
            setPaymentGatewayId(Number(newValue));
          }}
        >
          {payment_options.map((pay) => (
            <FormControlLabel
              key={pay.id}
              value={pay.id.toString()}
              control={<Radio color="secondary" />}
              label={pay.payment_gateway_name}
            />
          ))}
        </RadioGroup>
      </div>
      <div className="button-container">
        <Button
          variant="outlined"
          onClick={onClose}
          disabled={addBooking.isLoading}
        >
          {langData("Cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={addBooking.isLoading}
        >
          {langData("Confirm and Pay")}
        </Button>

        <form ref={paymentFormRef} style={{ display: "none" }}></form>
      </div>
    </div>
  );
}
