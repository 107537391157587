import { useParams } from "react-router-dom";

export default function PaymentLink() {
  const { link } = useParams();
  window.open(
    `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction${link}`,
    "_self"
  );
  return <p>Loading...</p>;
}
