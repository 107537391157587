import "./tsquality.css";

export default function TsQuality({ data }) {
  return (
    <div className="quality-container">
      <h5>{data?.heading}</h5>
      <h3>{data?.sub_heading}</h3>

      <div className="quality-card-container">
        {data?.content.map((elem, num) => (
          <img
            key={num + 1}
            className={`quality-card-icn`}
            src={elem?.image_name}
          />
        ))}
      </div>
    </div>
  );
}
