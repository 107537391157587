export default function IconEdit({
  width = "1em",
  height = "1em",
  color = "var(--clr-primary)",
  color2 = "var(--clr-secondary)",
  ...props
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1394_626)">
        <path d="M27.5878 9.83662L13.9556 23.4688C13.4959 23.9286 12.8705 24.184 12.2203 24.1776L6.53521 24.1217C5.23143 24.1089 4.17212 23.0656 4.13943 21.7621L4.00076 16.2322C3.98414 15.5694 4.24012 14.9289 4.70891 14.4601L18.4601 0.708905C19.4053 -0.236302 20.9378 -0.236302 21.883 0.708905L27.5878 6.41373C28.533 7.35894 28.533 8.89142 27.5878 9.83662Z" fill={color2} />
        <rect x="4" y="29.9932" width="24.057" height="3.00712" rx="1.50356" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_1394_626">
          <rect width="33" height="33" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}