import { Button, Checkbox, FormControlLabel, InputAdornment, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import IconCheckboxUnchecked from "../../../assets/icons/icon-common/icon-checkbox-unchecked";
import IconCheckboxChecked from "../../../assets/icons/icon-common/icon-checkbox-checked";
import { Fragment } from "react";
import { useGetBookingWorkshopTimings } from "../booking-api";
import { useLanguage } from "../../../language/useLanguage";

export const ISSUES_DATA = [
  { id: "engine", label: "With Engine" },
  { id: "gearbox", label: "With Gear Box" },
  { id: "clutch", label: "With Clutch" },
  { id: "other", label: "Other" },
];

export default function FormDateTime() {
  const { watch, control, formState, register, setValue, setFocus } = useFormContext();
  const { errors } = formState;
  const langData = useLanguage();
  const [booking_date, booking_time, notes, workshop_id] = watch(["booking_date", "booking_time", "notes", "workshop_id"]);
  const { data: wsDateTime, isLoading } = useGetBookingWorkshopTimings(workshop_id);
  if (isLoading) return (<p>Loading...</p>);
  // console.debug(booking_date, booking_time);
  const timeSlots = wsDateTime.find(day => day.date === booking_date)?.time_slots;
  const descLength = notes.length;
  return (
    <div className="form-date-time">
      <div className="field-container" style={{ marginBottom: "16px" }}>
        <label>{langData("Select Date")}</label>
        <Controller
          name="booking_date"
          rules={{ required: "Select Date" }}
          control={control}
          render={(props) => {
            return (
              <div className="reschedule_dates" style={{ display: "flex", gap: "1em", flexWrap: "wrap" }}>
                {wsDateTime.map((day) => (
                  <Button
                    key={day.date}
                    className={day.date === booking_date ? "Active" : ""}
                    variant="outlined"
                    color="primary"
                    style={{ height: 75 }}
                    disabled={!day.status}
                    onClick={() => {
                      props.field.onChange(day.date);
                      setValue("booking_time", null);
                    }}
                  >
                    {day.day}<br />{day.date.split("-")[2]}
                  </Button>
                ))}
              </div>
            )
          }}
        />
      </div>
      <div className="field-container" style={{ marginBottom: "16px" }}>
        <label className="required">{langData("Select Time")}</label>
        <Controller
          name="booking_time"
          rules={{ required: langData("Select Time") }}
          control={control}
          render={(props) => {
            return (
              <div className="reschedule_dates" style={{ display: "flex", gap: "1em", flexWrap: "wrap", justifyContent: "flex-start" }}>
                {!booking_date ? (
                  <p>{langData("Please Select Date")}</p>
                ) : timeSlots.length > 0 ? (
                  timeSlots.map((time) => (
                    <Button
                      key={time}
                      className={time === booking_time ? "Active" : ""}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        props.field.onChange(time ?? null);
                        setFocus("notes");
                      }}
                      style={{
                        flexBasis: 160,
                        paddingInline: 4,
                      }}
                    >
                      {time}
                    </Button>
                  ))
                ) : (
                  <p style={{ textAlign: "center", fontWeight: 500, color: "var(--clr-secondary)" }}>{langData("No slots available<br />Please choose other date")}</p>
                )}
              </div>
            )
          }}
        />
        <p className="form-error-text">{errors.booking_time?.message}</p>
      </div>
      <div className="field-container">
        <label>{langData("Notes")}</label>
        <TextField
          multiline
          rows={4}
          placeholder={langData("Add Your Notes Here")}
          InputProps={{
            endAdornment:
              <InputAdornment
                position="end"
                sx={{
                  position: "absolute",
                  right: 16,
                  bottom: 16,
                  fontSize: 12
                }}
              >
                <p>{`${descLength} / 1000`}</p>
              </InputAdornment>
          }}
          {...register("notes", {
            minLength: { value: 2, message: "Atleast two character require" },
            maxLength: { value: 1000, message: "Limit exceed, 1000 character allowed" },
          })}
          error={!!errors.notes}
        />
        <p className="form-error-text">{errors.notes?.message}</p>
      </div>
      <div className="field-container">
        <label>{langData("Are you facing any other problem with your tractor?")}</label>
        <Controller
          name="issues"
          control={control}
          render={(props) => {
            return (
              <MultiSelectCheckbox {...props} />
            )
          }}
        />
      </div>
      {/* <div className="form-btn-container"> */}
      <Button
        hidden
        id="submit-booking"
        variant="contained"
        type="submit"
        style={{ display: "none" }}
      >
        {langData("Proceed")}
      </Button>
      {/* </div> */}
    </div>
  )

}

function MultiSelectCheckbox({ field }) {
  const langData = useLanguage();
  function handleChange(event, isChecked) {
    const name = event.target.value;
    const text = "";
    if (isChecked) {
      // field.onChange((value) => ({ ...value, [name]: text }))
      field.onChange({ ...field.value, [name]: text })
    } else {
      delete field.value[name];
      field.onChange(field.value);
    }
  }

  function handleChangeText(event) {
    const { name, value: valueText } = event.target;
    console.debug(field.value);
    const data = { ...field.value, [name]: valueText };
    field.onChange(data);
  }

  return (
    <div ref={field.ref} style={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
      {ISSUES_DATA.map((obj) => (
        <Fragment key={obj.id}>
          <FormControlLabel
            control={
              <div>
                <Checkbox
                  icon={<IconCheckboxUnchecked />}
                  checkedIcon={<IconCheckboxChecked />}
                  onChange={handleChange}
                  onBlur={field.onBlur}
                  value={obj.id}
                  checked={!!Object.keys(field.value).includes(obj.id)}
                />
                <span style={{ color: "var(--clr-black)", fontSize: 14 }}>{obj.id === "other" ? langData(obj.label) : `${langData(obj.label)}`}</span>
              </div>
            }
          />
          {obj.id === "other" && Object.keys(field.value).includes(obj.id) ? (
            <TextField
              name={obj.id}
              value={field.value[obj.id] ?? ""}
              onChange={handleChangeText}
              onBlur={field.onBlur}
              placeholder="Please Mention"
            />
          ) : (
            <div></div>
          )}
        </Fragment>
      ))}
    </div>
  )
}