import "./major-benefits.css"
import IconLongTermReliablity from "../../../assets/icons/icon-common/icon-long-term-reliablity";
import IconPeaceOfMind from "../../../assets/icons/icon-common/icon-peace-of-mind";
import IconProtectInvestment from "../../../assets/icons/icon-common/icon-protect-investment";
import IconSeamlessCoverage from "../../../assets/icons/icon-common/icon-seamless-coverage";
import { useLanguage } from "../../../language/useLanguage";

export default function MajorBenefits() {
    const langData = useLanguage();
    return (
        <>
            <div className="major-benifit">
                <div className="intro-heading">
                    <h5>{langData("Why Do You Need Extended Warranty?")}</h5>
                </div>
                <div className="major-benifit-intro">
                    <div className="intro-policy">
                        <p className="text-title" dangerouslySetInnerHTML={{ __html: langData("When you buy a tractor, <b>you&apos;ve already chosen excellence.</b>") }}>
                        </p>
                    </div>
                </div>
                <div className="major-benifit-intro2">
                    <p className="text-desc" style={{ marginBottom: "1rem" }}>
                        {langData("However, even the most robust components can face unforeseen challenges. Our attractive extended warranty backed by BOSCH sets you up perfectly for worst-case scenarios.")}
                    </p>
                    <p className="text-desc">
                        {langData("This enhanced warranty, alternatively known as a service contract or maintenance agreement, is an additional safeguard that extends beyond the standard coverage for your fuel pump. While it offers an extended sense of assurance, it is crucial to thoroughly examine the terms and conditions associated with the extended warranty prior to committing to a purchase.")}
                    </p>
                </div>

            </div>
            <div className="major-benefit-cantainer-card">
                <h3 dangerouslySetInnerHTML={{ __html: langData("Here&apos;s why opting for an extended warranty on your fuel injection pump is a smart choice:") }}>
                </h3>
                <CardCaintener
                    bnfticn={<IconSeamlessCoverage width="3rem" height="3rem" />}
                    bnfttitle="Seamless Coverage"
                    bnftsubtitle="Comprehensive protection for your fuel injector pump beyond the standard warranty period. Moreover, you can purchase the extended warranty directly from our website, ensuring a hassle-free experience."
                />
                <CardCaintener
                    bnfticn={<IconProtectInvestment width="3rem" height="3rem" />}
                    bnfttitle="Protect Your Investment"
                    bnftsubtitle="A fuel injection pump failure can lead to costly repairs and downtime. Our extended warranty shields you from unexpected expenses, ensuring your investment remains safeguarded."
                />
                <CardCaintener
                    bnfticn={<IconPeaceOfMind width="3rem" height="3rem" />}
                    bnfttitle="Peace of Mind"
                    bnftsubtitle="Enjoy the tranquillity of knowing that your tractor&apos;s fuel pump is covered beyond the standard warranty period. Drive with confidence, knowing that you&apos;re protected against potential malfunctions."
                />
                <CardCaintener
                    bnfticn={<IconLongTermReliablity width="3rem" height="3rem" />}
                    bnfttitle="Long-Term Reliability"
                    bnftsubtitle="As pioneers in the tractor servicing industry, we believe in setting new benchmarks. Our extended warranty aligns with this commitment, providing you with long-term reliability and peace of mind."
                />
            </div>
        </>
    )
}

function CardCaintener({ bnfticn, bnfttitle, bnftsubtitle }) {
    const langData = useLanguage();
    return (
        <div className="major-benefits-card">
            <div className="major-benefits-icn">
                <div>{bnfticn}</div>
            </div>
            <div className="major-benefits-title"><b>{langData(bnfttitle)}</b></div>
            <p className="major-benefits-subtitle">{langData(bnftsubtitle)}</p>
        </div>
    );
}
