import { ThemeProvider, createTheme } from "@mui/material/styles";
let theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#002852",
      contrastText: "#fff",
      dark: "#002242",
      light: "#002e61",
    },
    secondary: {
      main: "#ec2726",
      contrastText: "#ffffff",
      light: "#ee3a39",
      dark: "#d81513",
    },
    info: {
      main: "#ffffff",
      contrastText: "#002852",
      light: "#555555",
      dark: "#f4f4f4",
    },
    white: {
      main: '#ffffff',
      contrastText: '#002852',
      light: '#999999',
      dark: '#bbbbbb',
    },
  },
  typography: {
    fontFamily: "gilroy",
  },
});
export default function ThemeContextWrapper({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
