import useAxios from "../../../../../config/axios/useAxios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { promptActions, promptContext } from "../../../../../context/prompt";
import { useAuthContext } from "../../../../../context/authContext";

export function useGetFranchiseStates() {
  const API = "inquiry/get_state";
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);

  return useQuery({
    queryKey: [API],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(API);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken("clearToken");
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Token Expired" } });
        }
        return;
      }
    },
  });
}

export function useAddFranchiseInquiry() {
  const API = "inquiry/franchise";
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: (form) => protectedAxios.post(`${API}`, form),
    onSuccess: (res) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          type: "success",
          message: res.data?.message || "Updated Succesfully"
        },
      });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || "Error while adding",
        },
      });
    },
  });
}

export function useAddContactInquiry() {
  const API = "inquiry/contact-us";
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: (form) => protectedAxios.post(`${API}`, form),
    onSuccess: (res) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          type: "success",
          message: res.data?.message || "Updated Succesfully"
        },
      });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || "Error while adding",
        },
      });
    },
  });
}