export default function WarrantyPurchaseBanner() {
  return (
    <div className="warranty-purchase-banner-container">
      <h1 style={{
        color: "white",
        position: "absolute",
        top: "50%",
        left: "4rem"
      }}>
        Extend Warranty Purchase Request
      </h1>
      <div className="banner" />
    </div>
  )
}