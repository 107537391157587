export default function IconCancelFile({
  width = "1em",
  height = "1em",
  // color = "var(--clr-primary)",
  color2 = "var(--clr-secondary)",
  ...props
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1082_29425)">
        <path d="M9.49992 1.58325C5.122 1.58325 1.58325 5.122 1.58325 9.49992C1.58325 13.8778 5.122 17.4166 9.49992 17.4166C13.8778 17.4166 17.4166 13.8778 17.4166 9.49992C17.4166 5.122 13.8778 1.58325 9.49992 1.58325ZM12.9041 12.9041C12.5953 13.2128 12.0966 13.2128 11.7878 12.9041L9.49992 10.6162L7.212 12.9041C6.90325 13.2128 6.4045 13.2128 6.09575 12.9041C5.787 12.5953 5.787 12.0966 6.09575 11.7878L8.38367 9.49992L6.09575 7.212C5.787 6.90325 5.787 6.4045 6.09575 6.09575C6.4045 5.787 6.90325 5.787 7.212 6.09575L9.49992 8.38367L11.7878 6.09575C12.0966 5.787 12.5953 5.787 12.9041 6.09575C13.2128 6.4045 13.2128 6.90325 12.9041 7.212L10.6162 9.49992L12.9041 11.7878C13.2049 12.0887 13.2049 12.5953 12.9041 12.9041Z" fill={color2} />
      </g>
      <defs>
        <clipPath id="clip0_1082_29425">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}