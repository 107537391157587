import "./ctaapp.css";
// import { useLanguage } from "../../language/useLanguage";
import { useLanguage } from "../../../../../language/useLanguage";
export default function OurStatistics() {
  const langData = useLanguage();
  return (
    <div className="cta-app-container">
      <div className="app-des-container">
        <div className="app-program-title">
          {langData("Download our app to get most out of it")}
        </div>
        <p className="app-program-subtitle">
          {langData("We aim to revolutionize the tractor service industry by being the pioneers in offering")}
        </p>
        <div className="btn-container">
          <img
            className="app-cta-btn play-store"
            src="./images/app-cta-playstore-btn.svg"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.tractorseva.customer&pli=1",
                "_blank"
              )
            }
          />
          <img
            className="app-cta-btn app-store"
            src="./images/app-cta-app-store-btn.svg"
            onClick={() =>
              window.open(
                "https://apps.apple.com/in/app/ts-customer/id6472632200",
                "_blank"
              )
            }
          />
        </div>
      </div>
      <img
        className="app-img"
        src="./images/tractor-seva-app-cta-circle.png"
      />
    </div>
  );
}
