import "./contact-us-banner.css";
import { useLanguage } from "../../../../../language/useLanguage";
export default function ContactUsBanner() {
  const langData = useLanguage();
  return (
    <div className="banner-contact-us-container">
      <div className="banner-contact-us-des-container">
        <div className="banner-contact-us-title">{langData("Contact Us")}</div>
      </div>
      <div className="banner-contact-us-img-container">
        <div className="banner-contact-us-program-img">
          {/* <img className="bnnr-map-img" src="./images/india-map-cta.svg" /> */}
        </div>
      </div>
    </div>
  );
}
