import "./about-banner.css";
// import { Button } from "@mui/material";
import { useLanguage } from "../../../language/useLanguage";

export default function AboutBanner() {
  const langData = useLanguage();
  return (
    <div className="banner-about-container">
      <div className="banner-about-des-container">
        <div className="banner-about-title">{langData("About Us")}</div>
      </div>
      <div className="banner-about-img-container">
        <div className="banner-about-program-img">
          {/* <img className="bnnr-map-img" src="./images/india-map-cta.svg" /> */}
        </div>
      </div>
    </div>
  );
}
