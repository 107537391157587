import { useEffect, useState } from "react";
import "./banner-slider.css";

const slideStyles = {
  width: "100%",
  height: "100%",
  backgroundSize: "cover",
  backgroundPosition: "center",
  cursor: "pointer",
};

const sliderStyles = {
  position: "relative",
  height: "100%",
};
// const rightArrowStyles = {
//   position: "absolute",
//   top: "50%",
//   transform: "translate(0, -50%)",
//   right: "32px",
//   fontSize: "45px",
//   color: "#fff",
//   zIndex: 1,
//   cursor: "pointer",
// };

// const leftArrowStyles = {
//   position: "absolute",
//   top: "50%",
//   transform: "translate(0, -50%)",
//   left: "32px",
//   fontSize: "45px",
//   color: "#fff",
//   zIndex: 1,
//   cursor: "pointer",
// };

const slidesContainerStyles = {
  display: "flex",
  height: "100%",
  transition: "transform ease-out 0.5s",
};

const slidesContainerOverflowStyles = {
  overflow: "hidden",
  height: "100%",
};

// const dotsContainerStyles = {
//   display: "flex",
//   justifyContent: "center",
//   position: "absolute",
//   bottom: "20px",
//   left: "30px",
// };

const dotStyle = {
  margin: "0 3px",
  cursor: "pointer",
  fontSize: "20px",
  color: "#ffffff8a", // Adjust the color for the inactive dot as needed
};

const activeDotStyle = {
  ...dotStyle,
  color: "#fff", // Adjust the color for the active dot as needed
};

const ImageSlider = ({
  slides,
  responsiveSlides,
  duration,
  disableArrows = false,
  disableDots = false,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 600);
  const updateMobileView = () => {
    setIsMobileView(window.innerWidth < 600);
  };

  const handleResize = () => {
    updateMobileView();
    setCurrentIndex(0); // Reset index on resize
  };

  window.addEventListener("resize", handleResize);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide
      ? (isMobileView ? responsiveSlides : slides).length - 1
      : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const slidesArray = isMobileView ? responsiveSlides : slides;
    const isLastSlide = currentIndex === slidesArray.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  // const getSlideStylesWithBackground = (image_name) => ({
  //   ...slideStyles,
  //   backgroundImage: `url(${image_name})`,
  //   width: `${parentWidth}px`,
  // });
  const getSlideStylesWithBackground = (image_name) => (
    <div style={{ ...slideStyles }}>
      <img
        src={image_name}
        alt="slide"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "inherit",
        }}
      />
    </div>
  );

  // const getSlidesContainerStylesWithWidth = () => ({
  //   ...slidesContainerStyles,
  //   width: parentWidth * (isMobileView ? responsiveSlides : slides).length,
  //   transform: `translateX(${-(currentIndex * parentWidth)}px)`,
  // });
  const getSlidesContainerStylesWithWidth = () => {
    const totalSlides = isMobileView ? responsiveSlides.length : slides.length;
    return {
      ...slidesContainerStyles,
      width: `${totalSlides * 100}%`,
      transform: `translateX(${-(currentIndex * (100 / totalSlides))}%)`,
    };
  };

  useEffect(() => {
    // Auto slide every 5 seconds (adjust the interval as needed)
    const slidesArray = isMobileView ? responsiveSlides : slides;
    if (slidesArray.length > 1) {
      const intervalId = setInterval(() => {
        goToNext();
      }, duration ?? 2500);

      // Clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [currentIndex, isMobileView, responsiveSlides, slides, duration]);

  return (
    <div style={sliderStyles}>
      {disableArrows || slides.length <= 1 ? null : (
        <div>
          <div onClick={goToPrevious} className="left_arrow_styles">
            ❰
          </div>
          <div onClick={goToNext} className="right_arrow_styles">
            ❱
          </div>
        </div>
      )}
      <div style={slidesContainerOverflowStyles}>
        {/* <div style={getSlidesContainerStylesWithWidth()}>
          {(isMobileView ? responsiveSlides : slides).map(
            (imageData, slideIndex) => (
              <div
                key={slideIndex}
                style={getSlideStylesWithBackground(imageData?.image_name)}
                onClick={() =>
                  imageData.link && window.open(imageData.link, "_blank")
                }
              ></div>
            )
          )}
        </div> */}
        <div style={getSlidesContainerStylesWithWidth()}>
          {(isMobileView ? responsiveSlides : slides).map(
            (imageData, slideIndex) => (
              <div
                key={slideIndex}
                onClick={() =>
                  imageData.link &&
                  window.open(`https://${imageData.link}`, "_blank")
                }
              >
                {getSlideStylesWithBackground(imageData.image_name)}
              </div>
            )
          )}
        </div>
      </div>
      {disableDots || slides.length <= 1 ? null : (
        <div className="dots_container_styles">
          {(isMobileView ? responsiveSlides : slides).map(
            (slide, slideIndex) => (
              <div
                style={currentIndex === slideIndex ? activeDotStyle : dotStyle}
                key={slideIndex}
                onClick={() => goToSlide(slideIndex)}
              >
                ●
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default ImageSlider;
