import "./warranty-extand.css";
import { useLanguage } from "../../../language/useLanguage";

export default function WarrantyExtend() {
    const langData = useLanguage();
    return (
        <div className="warranty">
            <div className="wcantainer">
                <div className="intro">
                    <p className="text-title" dangerouslySetInnerHTML={{ __html: langData("Ensure Your Tractor&apos;s Heart Keeps Beating.<br /><b>Buy Extended Warranty on Fuel Injection Pump</b>") }}>

                    </p>
                    <p style={{ marginBottom: "1rem", marginTop: "1rem" }} className="text-desc" dangerouslySetInnerHTML={{ __html: langData("At our multi-brand tractor service company, we recognize the beating heart of your machine - the fuel injection pump. It&apos;s a component often overlooked, yet its significance cannot be overstated.") }}></p>
                    <p className="text-desc" dangerouslySetInnerHTML={{ __html: langData("For those seeking added protection to maintain your tractor&apos;s durability, you can now secure your fuel injection pump with an extended warranty exclusively offered by BOSCH, available for hassle-free purchase on our website.") }}>
                    </p>
                </div>
                <img className="images" src="/images/about-us-industry.png" />

            </div>
            <div className="wcantainer container2">
                <div className="cantaineritem2">
                    <p style={{ marginTop: "1rem", marginBottom: "1rem" }} className="text-title" dangerouslySetInnerHTML={{ __html: langData("Why the <b>Fuel Injection <br />Pump Matters</b>") }}>

                    </p>
                    <p style={{ marginBottom: "1rem" }} className="text-desc" dangerouslySetInnerHTML={{ __html: langData("For those seeking added protection to maintain your tractor&apos;s durability, you can now secure your fuel injection pump with an extended warranty exclusively offered by BOSCH, available for hassle-free purchase on our website.") }}></p>
                    <p style={{ marginBottom: "1rem" }} className="text-desc" dangerouslySetInnerHTML={{ __html: langData("But there&apos;s more to the story – the age and condition of your vehicle significantly influence the working quality of the fuel pump. Older vehicles may be more prone to experiencing fuel pump issues due to natural wear and tear over time.") }}></p>
                    <p style={{ marginBottom: "1rem" }} className="text-desc" dangerouslySetInnerHTML={{ __html: langData("That&apos;s why we offer solutions that go beyond standard servicing. Here at Tractor Seva, we understand the importance of your tractor running smoothly and efficiently on the road. Maximize your fuel pump warranty coverage with us & ensure peace of mind on every journey.") }}></p>
                </div>
                <img className="images" src="/images/extened-warranty-image1.jpg" />
            </div>
        </div>
    )
}
