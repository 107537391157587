import { useForm } from "react-hook-form";
import { useAddProfile } from "../login-api";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, InputAdornment, TextField } from "@mui/material";
import IconCheck from "../../../assets/icons/icon-common/icon-check";
import { useAuthContext } from "../../../context/authContext";
import { useLanguage } from "../../../language/useLanguage";

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone_no: ""
}

export default function FormProfile({
  phone_no = "",
  tempToken
}) {
  const navigate = useNavigate();
  const addProfile = useAddProfile(tempToken);
  const { setToken } = useAuthContext();
  const location = useLocation();
  const langData = useLanguage();
  defaultValues.phone_no = phone_no;
  const { register, handleSubmit, formState: { errors } } = useForm({ defaultValues });
  function submit(inputs) {
    console.debug('SUBMITTING: ', inputs);

    const form = new FormData();
    for (const key in inputs) { form.append(key, inputs[key]) }

    addProfile.mutate(form, {
      onSuccess: (response, form) => {
        console.debug('PROFILE_SUCCESS: ', { response, form });
        setToken(response.data.token);
        if (location?.state?.redirectTo) {
          navigate(location?.state?.redirectTo, { state: location.state });
        } else {
          navigate("/");
        }
      },
      onError: (err, form) => {
        console.debug('PROFILE_ERROR: ', { err, form });
      },
    })
  }

  return (
    <form className="login-form profile-form" noValidate onSubmit={handleSubmit(submit)}>
      <p className="title">{langData("Profile Details")}</p>
      <p className="error-text">{langData("Setup your profile details")}</p>
      <div className="field-input">
        <label>{langData("First Name")}</label>
        <TextField
          {...register("first_name", {
            required: langData("Field is Required"),
            minLength: { value: 2, message: "Minimum 2 character required" },
            maxLength: { value: 30, message: "Maximum limit exceed" }
          })}
          error={!!errors.first_name}
        />
        <p className="error-text">{errors.first_name?.message}</p>
        <label>{langData("Last Name")}</label>
        <TextField
          {...register("last_name", {
            required: langData("Field is Required"),
            minLength: { value: 2, message: "Minimum 2 character required" },
            maxLength: { value: 30, message: "Maximum limit exceed" }
          })}
          error={!!errors.last_name}
        />
        <p className="error-text">{errors.last_name?.message}</p>
        <label>Email ID</label>
        <TextField
          {...register("email", {
            required: langData("Field is Required"),
            minLength: { value: 2, message: "Minimum 2 character required" },
            maxLength: { value: 30, message: "Maximum limit exceed" }
          })}
          error={!!errors.email}
        />
        <p className="error-text">{errors.email?.message}</p>
        <label>{langData("Contact Number")}</label>
        <TextField
          {...register("phone_no", {
            required: langData("Field is Required"),
            minLength: { value: 2, message: "Minimum 2 character required" },
            maxLength: { value: 30, message: "Maximum limit exceed" }
          })}
          error={!!errors.phone_no}
          disabled
          InputProps={{
            startAdornment: <InputAdornment position="start">+91 |</InputAdornment>,
            endAdornment: <IconCheck color="#20A867" />
          }}
        />
        <p className="error-text">{errors.phone_no?.message}</p>
      </div>
      <Button variant="contained" type="submit" disabled={addProfile.isLoading}>{langData("Proceed")}</Button>
    </form>
  )
}
