import "./processworks.css";

export default function ProcessWorks({ data }) {
  return (
    <div className="process-container">
      <div className="how-it-works-container">
        <div className="gif-container">
          {/* <img className="hiw-gif" src="./images/how-it-works-asset-1.svg" /> */}
          <img
            className="hiw-gif"
            src={data?.image[0]?.image_name}
            alt="process"
            onClick={() =>
              data?.image[0].link && window.open(data?.image[0].link, "_blank")
            }
          />
        </div>
        <div className="process-card-container">
          <div className="process-card-head-container">
            <h5>{data?.heading}</h5>
            <h3>{data?.sub_heading}</h3>
          </div>
          {data?.content.map((elem, num) => (
            <ProcessCard
              key={num + 1}
              title={elem.title}
              subtitle={elem.description}
              prcnumber={elem.no}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
function ProcessCard({ title, subtitle, prcnumber }) {
  return (
    <div className="process-card">
      <div className="process-number-container">
        <div className="process-number">{prcnumber}</div>
      </div>
      <div className="process-card-des-container">
        <h4 className="process-card-title">{title}</h4>
        <p className="process-card-subtitle">{subtitle}</p>
      </div>
    </div>
  );
}
