export default function IconReload({
  width = "1em",
  height = "1em",
  color = "currentColor",
  ...props
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12" fill="none" {...props}>
      <path d="M6 1C8.76142 1 11 3.23858 11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 4.4293 1.72426 3.02775 2.85704 2.11111M1 1.83333H3.22222V4.05556" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}