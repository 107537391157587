import { createContext, useReducer } from "react"
import Prompt from "../assets/prompt/custom-prompt";

//Create Context
export const promptContext = createContext();

//Initial State and Actions
const promptInitialState = [];

export const promptActions = {
  SHOW_PROMPT: "SHOW_PROMPT",
  HIDE_PROMPT: "HIDE_PROMPT",
}

//Reducer to Handle Actions
const promptReducer = (state, action) => {

  const newPrompt = {
    id: Math.floor(Math.random() * 1000),
    timer: action.payload?.timer ?? 4000,
    type: action.payload?.type ?? 'error', // 'success' | 'error' \ 'info' | 'warn'
    message: action.payload?.message ?? 'Something Went Wrong',
  };

  switch (action.type) {
    case promptActions.SHOW_PROMPT:
      return [...state, newPrompt];
    case promptActions.HIDE_PROMPT:
      return state.filter((prompt) => prompt.id !== action.payload);
    default:
      console.warn(`Invalid Action Found: ${action.type}`);
      return state;
  }
}

export default function PromptContextWrapper({ children }) {

  const [promptState, promptDispatch] = useReducer(promptReducer, promptInitialState);

  return (
    <promptContext.Provider value={promptDispatch}>
      {children}
      <Prompt data={promptState} dispatchFn={promptDispatch} />
    </promptContext.Provider>
  )
}