// import { NavLink } from 'react-router-dom';
import HeaderWeb from "./header/header-web";
import HeaderResponsive from "./header/header-responsive";
import { HashLink } from "react-router-hash-link";
import { useGetProfile } from "../profile/profile-api";
import { useAuthContext } from "../../context/authContext";
import { useLanguage } from "../../language/useLanguage";



export const HEADER_MENU = [
  {
    title: "Home",
    path: "/",
    subItems: null,
    target: "",
  },
  {
    title: "Book Now",
    path: "/#select-service",
    subItems: null,
    target: "",
  },
  {
    title: "About Us",
    path: "/about-us",
    subItems: null,
    target: "",
  },
  {
    title: "Warranty",
    path: "/warranty",
    subItems: null,
    target: ""
  },
  // {
  //   title: "Know Tractor Seva",
  //   path: "https://about.tractorseva.com/",
  //   subItems: null
  // },
  {
    title: "Franchise",
    path: "/franchise",
    subItems: null,
    target: "",
  },
  {
    title: "Contact Us",
    path: "/contact-us",
    subItems: null,
    target: "",
  },
  // {
  //   title: "Download App",
  //   path: "https://play.google.com/store/apps/details?id=com.tractorseva.customer",
  //   subItems: null,
  //   target: "_blank"
  // },
];

export const SIDEBAR_MENU = [
  // {
  //   title: "Know Tractor Seva",
  //   path: "https://about.tractorseva.com/",
  //   subItems: null
  // },
  {
    title: "Home",
    path: "/",
    subItems: null,
    target: "",
  },
  {
    title: "Book Now",
    path: "/#select-service",
    subItems: null,
    protected: false,
    target: "",
  },
  {
    title: "About Us",
    path: "/about-us",
    subItems: null,
    protected: false,
    target: "",
  },
  {
    title: "Warranty",
    path: "/warranty",
    subItems: null,
    target: ""
  },
  {
    title: "My Booking",
    path: "/booking",
    subItems: null,
    protected: true,
    target: "",
  },
  {
    title: "My Profile",
    path: "/profile",
    subItems: null,
    protected: true,
    target: "",
  },
  {
    title: "Franchise",
    path: "/franchise",
    subItems: null,
    protected: false,
    target: "",
  },
  {
    title: "Contact Us",
    path: "/contact-us",
    subItems: null,
    protected: false,
    target: "",
  },
  // {
  //   title: "Download App",
  //   path: "https://play.google.com/store/apps/details?id=com.tractorseva.customer",
  //   subItems: null,
  //   protected: false,
  //   target: "_blank",
  // },
];

const languageOptions = [
  {
    id: "EN",
    label: "English",
    sortLabel: "En",
  },
  {
    id: "HI",
    label: "हिन्दी",
    sortLabel: "हिन्दी",
  },
  {
    id: "MR",
    label: "मराठी",
    sortLabel: "मराठी",
  },
];

const header_secondary = [
  "booking",
  "profile",
  "cancellation-policy",
  "terms-and-condition",
  "service-agreement",
  "privacy-policy",
  "warranty-bosch-t&c"
];

export default function Header() {
  const { getData } = useAuthContext();
  const { userData } = getData();
  const { data, isLoading } = useGetProfile();
  const image = !isLoading && data?.image ? data.image : `${userData?.first_name[0]}`;
  const header_class = header_secondary.includes(
    location.pathname.split("/")[1]
  );

  return (
    <header id="header" className={`${header_class ? "header-blue" : ""}`}>
      {window.innerWidth > 600 ? (
        <HeaderWeb
          HEADER_MENU={HEADER_MENU}
          languageOptions={languageOptions}
          isImage={Boolean(data?.image)}
          image={image}
        />
      ) : (
        <HeaderResponsive
          SIDEBAR_MENU={SIDEBAR_MENU}
          languageOptions={languageOptions}
          isImage={Boolean(data?.image)}
          image={image}
        />
      )}
    </header>
  );
}

export function MenuOnly({ item }) {
  const langData = useLanguage();
  return (
    <HashLink className="sidebar-main-link" to={item.path} target={item.target}>
      <div className={`sidebar-title`}>{langData(item.title)}</div>
    </HashLink>
  );
}
