export default function IconCheck({
  width = "1em",
  height = "1em",
  color = "currentColor",
  ...props
}) {
  return (
    <svg width={width} height={height} viewBox="0 0  79 79" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="check">
        <circle id="Ellipse 300" cx="39.5" cy="39.5" r="39.5" fill={color} />
        <path id="Vector 843" d="M52.718 32.3184L34.3305 49.7381L25.1367 41.0282" stroke="white" strokeWidth="5.80658" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}