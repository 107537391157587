import { Button, Dialog, FormControlLabel, InputAdornment, Radio, RadioGroup, TextField } from '@mui/material';
import IconEdit from '../../../assets/icons/icon-common/icon-edit';
import './personal-details.css';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import IconCheck from '../../../assets/icons/icon-common/icon-check';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import IconDate from '../../../assets/icons/icon-common/icon-date';
import { useUpdateProfile } from '../profile-api';
import { dateFormat } from '../../../config/common-function/date-time-format';
import { useLanguage } from '../../../language/useLanguage';

export default function PersonalDetails({ data }) {

  const [edit, setEdit] = useState(false);
  const handleEditClose = () => setEdit(false);
  const langData = useLanguage();
  const { first_name, last_name, phone_no, gender, email, dob } = data;

  return (
    <div className="personal-details">
      <div className="heading-container">
        <h2>{langData("Personal Details")}</h2>
        <div className='icon-edit' onClick={() => setEdit(true)}><IconEdit /></div>
      </div>
      <div className="personal-details-grid">
        <p className="grid-title">{langData("First Name")}</p>
        <p>{first_name}</p>
        <p className="grid-title">{langData("Last Name")}</p>
        <p>{last_name}</p>
        <p className="grid-title">{langData("Phone Number")}</p>
        <p>+91 {phone_no}</p>
        <p className="grid-title">{langData("Gender")}</p>
        <p style={{ textTransform: "capitalize" }}>{gender ?? "-"}</p>
        <p className="grid-title">{langData("Email")}</p>
        <p>{email}</p>
        <p className="grid-title">{langData("Date of Birth")}</p>
        <p>{dob ? dateFormat(dob) : "-"}</p>
      </div>
      <EditProfileModel
        open={edit}
        onClose={handleEditClose}
        inputs={{
          first_name,
          last_name,
          phone_no,
          gender,
          email,
          dob,
        }}
      />
    </div>
  )
}

function EditProfileModel({
  open = false,
  onClose,
  inputs,
}) {
  const updateProfile = useUpdateProfile();
  const { register, control, formState: { errors }, handleSubmit } = useForm({ defaultValues: inputs });
  const langData = useLanguage();
  function submit(inputs) {
    console.debug('SUBMITTING: ', inputs);
    const form = new FormData();
    for (const key in inputs) { form.append(key, inputs[key]) }
    updateProfile.mutate(form, {
      onSuccess: () => {
        onClose();
      },
    })
  }
  return (
    <Dialog open={open} onClose={onClose} className='popup edit-profile'>
      <form className='form-container' noValidate onSubmit={handleSubmit(submit)}>
        <h2>{langData("Edit Profile")}</h2>
        <div className='form-column-2'>
          <div className='field-container'>
            <label className="required">{langData("First Name")}</label>
            <TextField
              {...register("first_name", {
                required: langData("Field is Required"),
                minLength: { value: 2, message: "Minimum 2 character required" },
                maxLength: { value: 30, message: "Maximum limit exceed" }
              })}
              error={!!errors.first_name}
            />
            <p className="error-text">{errors.first_name?.message}</p>
          </div>
          <div className='field-container'>
            <label className="required">{langData("Last Name")}</label>
            <TextField
              {...register("last_name", {
                required: langData("Field is Required"),
                minLength: { value: 2, message: "Minimum 2 character required" },
                maxLength: { value: 30, message: "Maximum limit exceed" }
              })}
              error={!!errors.last_name}
            />
            <p className="error-text">{errors.last_name?.message}</p>
          </div>
        </div>
        <div className='form-column-2'>
          <div className='field-container'>
            <label className="required">{langData("Phone Number")}</label>
            <TextField
              {...register("phone_no", {
                required: langData("Field is Required"),
                minLength: { value: 2, message: "Minimum 2 character required" },
                maxLength: { value: 30, message: "Maximum limit exceed" }
              })}
              error={!!errors.phone_no}
              disabled
              InputProps={{
                startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                endAdornment: <IconCheck color="#20A867" />
              }}
            />
            <p className="error-text">{errors.phone_no?.message}</p>
          </div>
          <div className='field-container'>
            <label className="required">{langData("Gender")}</label>
            <Controller
              rules={{ required: "Select Gender" }}
              control={control}
              name="gender"
              render={(props) => {
                return (
                  <RadioGroup style={{ marginTop: "-10px" }} row {...props.field}>
                    <FormControlLabel style={{ marginBottom: 0 }} value="male" control={<Radio color="secondary" />} label={langData("Male")} />
                    <FormControlLabel style={{ marginBottom: 0 }} value="female" control={<Radio color="secondary" />} label={langData("Female")} />
                    <FormControlLabel style={{ marginBottom: 0 }} value="other" control={<Radio color="secondary" />} label={langData("Other")} />
                  </RadioGroup>
                )
              }}
            />
            <p className="error-text">{errors.gender?.message}</p>
          </div>
        </div>
        <div className='form-column-2'>
          <div className='field-container'>
            <label className="required">{langData("Email")}</label>
            <TextField
              {...register("email", {
                required: langData("Field is required"),
                minLength: { value: 2, message: "Minimum 2 character required" },
                maxLength: { value: 30, message: "Maximum limit exceed" }
              })}
              error={!!errors.email}
            />
            <p className="error-text">{errors.email?.message}</p>
          </div>
          <div className='field-container'>
            <label className="required">{langData("Date of Birth")}</label>
            <Controller
              name="dob"
              control={control}
              rules={{ required: "Please Select Date of Birth", }}
              render={({ field }) => {
                return (
                  <DatePicker
                    {...field}
                    format="DD MMM, YYYY"
                    value={dayjs(field.value, "YYYY-MM-DD")}
                    maxDate={dayjs()}
                    onChange={(dayJsObj) => field.onChange(dayJsObj.format("YYYY-MM-DD"))}
                    slots={{
                      openPickerIcon: () => <IconDate />
                    }}
                  />
                )
              }}
            />
            <p className="error-text">{errors.dob?.message}</p>
          </div>
        </div>
        <div style={{ display: 'flex', gap: 16, flexWrap: "wrap" }}>
          <Button variant='contained' type='submit'>{langData("Save")}</Button>
          <Button variant='outlined' onClick={onClose}>{langData("Cancel")}</Button>
        </div>
      </form>
    </Dialog>
  )
}