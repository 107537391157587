import AboutBanner from "./about-banner/about-banner";
import AboutUsSEO from "./about-seo";
import AboutDetails from "./about-us-details/about-us-details";
import AboutUsOurBenefits from "./about-us-our-benefits/about-us-our-benefits";

export default function About() {
  return (
    <div>
      <AboutUsSEO />
      <AboutBanner />
      <AboutDetails />
      <AboutUsOurBenefits />
    </div>
  );
}
