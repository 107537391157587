export default function IconDownload({ width = "1em", height = "1em", color = "var(--clr-primary)", color2 = "var(--clr-secondary)", ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M37.2358 22.9389L37.9582 22.9389C40.6714 22.9389 43.3846 22.9389 46.0978 22.9389C46.7598 22.9389 47.3221 23.0663 47.6114 23.7259C47.9007 24.3856 47.5983 24.8476 47.1782 25.3211C42.1463 30.9662 37.1181 36.6144 32.0938 42.266C30.8337 43.6832 29.1763 43.7044 27.9211 42.297C22.8771 36.6367 17.8354 30.9765 12.7958 25.3162C12.3741 24.8427 12.1044 24.3464 12.397 23.7129C12.6896 23.0794 13.2142 22.9438 13.868 22.9471C16.81 22.9602 19.7406 22.9471 22.7823 22.9471L22.7823 4.19155C22.7823 2.28121 23.763 1.28197 25.6704 1.2787C28.5688 1.2787 31.4656 1.2787 34.3608 1.2787C36.2339 1.2787 37.2391 2.28122 37.2391 4.13931C37.2391 10.1501 37.2391 16.1597 37.2391 22.1683L37.2358 22.9389Z" fill={color2} />
      <path d="M29.9976 49.4361C36.2979 49.4361 42.5987 49.4361 48.9001 49.4361C50.2926 49.4361 51.4449 50.0304 51.5495 51.2028C51.7162 53.2234 51.7162 55.2543 51.5495 57.275C51.4711 58.3134 50.4054 58.9649 49.3299 59.0367C49.1469 59.0498 48.9622 59.0498 48.7791 59.0498C36.2788 59.0498 23.7785 59.0498 11.2781 59.0498C10.4609 59.0498 9.69599 58.9192 9.08961 58.3118C8.84128 58.0687 8.64577 57.7771 8.51529 57.4551C8.38481 57.1332 8.32215 56.7879 8.33122 56.4406C8.33122 54.9712 8.31814 53.5017 8.33122 52.042C8.35083 50.4892 9.38707 49.4508 10.9578 49.4296C12.9763 49.4067 14.9965 49.4296 17.0151 49.4296L29.9976 49.4361Z" fill={color} />
    </svg>
  )
}
// export default function IconDownload({ width = "1em", height = "1em", color = "var(--clr-primary)", color2 = "var(--clr-secondary)", ...props }) {
//   return (
//     <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
//       <g clipPath="url(#clip0_1_5)">
//         <path d="M17.7654 13.8618C17.0218 13.8618 16.4188 14.4646 16.4188 15.2082V17.3069H3.58102V15.2082C3.58102 14.4647 2.9782 13.8618 2.23459 13.8618C1.49098 13.8618 0.888161 14.4646 0.888161 15.2082V18.6534C0.888161 19.3969 1.49098 20 2.23459 20H17.7654C18.509 20 19.1118 19.3969 19.1118 18.6534V15.2082C19.1118 14.4646 18.509 13.8618 17.7654 13.8618Z" fill={color} />
//         <path d="M9.59363 15.2948C9.81792 15.519 10.1823 15.519 10.4063 15.2948L15.125 10.5762C15.2625 10.4387 15.3033 10.2322 15.229 10.0521C15.1546 9.87269 14.9798 9.75573 14.785 9.75573H12.5694V1.05078C12.5694 0.470137 12.0993 0 11.5187 0H8.48193C7.90144 0 7.43127 0.470097 7.43127 1.05078V9.75573H5.21492C5.02031 9.75573 4.84549 9.87264 4.77115 10.0521C4.6968 10.2322 4.73768 10.4387 4.87496 10.5762L9.59363 15.2948Z" fill={color2} />
//       </g>
//       <defs>
//         <clipPath id="clip0_1_5">
//           <rect width="20" height="20" fill="white" />
//         </clipPath>
//       </defs>
//     </svg>
//   )
// }