import IconCustomerSupport from "../../../assets/icons/icon-common/icon-customer-support";
import IconMultipleServicingOptions from "../../../assets/icons/icon-about-us/multiple-servicing-options";
import IconOnlineBooking from "../../../assets/icons/icon-about-us/online-booking";
import IconQuality from "../../../assets/icons/icon-about-us/quality-product";
import IconSecurePaymentOption from "../../../assets/icons/icon-about-us/secure-payment-option";
import "./about-us-our-benefits.css";
import { useLanguage } from "../../../language/useLanguage";

export default function AboutUsOurBenefits() {
  const langData = useLanguage();
  return (
    <div className="about-us-our-benefits-main-container">
      <div className="about-us-our-benefits-container">
        <div className="about-us-our-benefits-title">
          <h5>{langData("Our Benefits")}</h5>
          <p dangerouslySetInnerHTML={{ __html: langData("We always go the extra mile to provide <b>unparalleled benefits to our customers,</b> here’s how") }}>
            {/* We always go the extra mile to provide{" "}
            <b>unparalleled benefits to our customers,</b> here’s how */}
          </p>
        </div>
        <div className="about-us-our-benefits-card-container">
          <AboutUsBenefitscards
            bnfticn={<IconOnlineBooking width="3.5rem" height="3.5rem" />}
            bnfttitle={langData("Hassle-free Online Booking")}
            bnftsubtitle={langData("Book your tractor service effortlessly through our user-friendly online platform, saving you time and effort.")}
          />

          <AboutUsBenefitscards
            bnfticn={<IconQuality width="3.5rem" height="3.5rem" />}
            bnfttitle={langData("Quality Products at Competitive Rates")}
            bnftsubtitle={langData("Enjoy access to genuine tractor parts and accessories at affordable prices, ensuring optimal performance and longevity for your tractor.")}
          />

          <AboutUsBenefitscards
            bnfticn={<IconCustomerSupport width="3.5rem" height="3.5rem" />}
            bnfttitle={langData("Customer Care Support")}
            bnftsubtitle={langData("Our dedicated customer care team is just a call away, providing you with prompt assistance and resolving your queries.")}
          />

          <AboutUsBenefitscards
            bnfticn={<IconSecurePaymentOption width="3.5rem" height="3.5rem" />}
            bnfttitle={langData("Secure Payment Options")}
            bnftsubtitle={langData("Pay with ease using UPI, credit card, or debit card, offering you multiple secure and convenient payment choices.")}
          />

          {/* <AboutUsBenefitscards
            bnfticn={<IconDoorStep width="3.5rem" height="3.5rem" />}
            bnfttitle={langData("Convenient At-Door Service")}
            bnftsubtitle={langData("Subscribe to our service or choose add-ons, and our technicians will come to your doorstep, delivering professional maintenance for your tractor.")}
          /> */}

          <AboutUsBenefitscards
            bnfticn={
              <IconMultipleServicingOptions width="3.5rem" height="3.5rem" />
            }
            bnfttitle={langData("Multiple Servicing Options")}
            bnftsubtitle={langData("Select from our range of service packages with additional benefits at the time of booking, giving you the flexibility to choose the services that suit your needs.")}
          />
        </div>
      </div>
    </div>
  );
}

function AboutUsBenefitscards({ bnfticn, bnfttitle, bnftsubtitle }) {
  return (
    <div className="our-benefits-card">
      <div className="benefits-icn">
        {/* <img className="icn" src={bnfticn} /> */}
        <div>{bnfticn}</div>
      </div>
      <div className="our-benefits-title">{bnfttitle}</div>
      <p className="our-benefits-subtitle">{bnftsubtitle}</p>
    </div>
  );
}
