export default function IconCheckboxUnchecked({
  width = "1em",
  height = "1em",
  ...props
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.5" y="0.5" width="18" height="18" rx="0.652128" stroke="#D6DCE3" />
    </svg>
  )
}