import dayjs from "dayjs";

export function dateFormat(dateStr) {
  const dayJsObj = dayjs(dateStr, "YYYY-MM-DD");
  if (dayJsObj.isValid) {
    return dayJsObj.format("DD MMM, YYYY");
  } else {
    return dateStr;
  }
}

export function timeFormat(timeStr) {
  const dayJsObj = dayjs(timeStr, "HH:mm", "HH:mm:ss");

  if (dayJsObj.isValid) {
    // console.error('ssss', timeStr);
    // console.warn('ttt');
    // console.log('ssss');
    return dayJsObj.format("hh:mm A");
  } else {
    return timeStr;
  }
}