export default function IconProtectInvestment({
    width = "1em",
    height = "1em",
    color = "var(--clr-primary)",
    color2 = "var(--clr-secondary)",
    ...props
}) {
    return (
        <svg
            className="hoverable"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <g clipPath="url(#clip0_4_26)">
                <path d="M8.07548 9.46308C7.14716 9.46308 6.43243 10.2189 6.43243 11.1061C6.43243 12.0344 7.18823 12.7492 8.07548 12.7492H20.7351C21.6634 12.7492 22.3782 11.9934 22.3782 11.1061C22.3782 10.1778 21.6224 9.46308 20.7351 9.46308H8.07548Z" fill={color2} />
                <path d="M20.234 8.62516C20.8255 7.27786 21.6635 6.02094 22.674 4.79687C20.4066 3.62209 18.6321 2.23372 19.1825 0.130632L16.9972 4.29574L17.3341 0.0895557C15.3131 2.06942 13.3415 2.02834 11.3616 0.0895557L11.7806 5.68411L8.79025 0.845355C8.95456 2.73485 7.82086 4.08215 5.71777 4.96939C6.76931 6.10309 7.56619 7.32715 8.11661 8.62516H20.234Z" fill={color2} />
                <path d="M16.5372 24.1518C16.5043 24.119 16.4633 24.1025 16.414 24.1025H15.4692C15.4035 23.7986 15.2885 23.5275 15.116 23.3057H16.4057C16.455 23.3057 16.4961 23.2892 16.5372 23.2564C16.5701 23.2235 16.5865 23.1824 16.5865 23.1332V22.5663C16.5865 22.517 16.5701 22.4759 16.5372 22.4431C16.5043 22.4102 16.4633 22.3938 16.4057 22.3938H11.8052C11.7559 22.3938 11.7149 22.4102 11.682 22.4431C11.6491 22.4759 11.6327 22.517 11.6327 22.5663V23.3057C11.6327 23.355 11.6491 23.396 11.682 23.4289C11.7149 23.4618 11.7559 23.4782 11.8052 23.4782H12.6103C13.3908 23.4782 13.8837 23.6836 14.0973 24.1025H11.8134C11.7642 24.1025 11.7231 24.119 11.6902 24.1518C11.6574 24.1847 11.6409 24.2258 11.6409 24.2751V24.8419C11.6409 24.8912 11.6574 24.9323 11.6902 24.9651C11.7231 24.998 11.7642 25.0144 11.8134 25.0144H14.1794C14.0973 25.3184 13.9083 25.5484 13.6126 25.7045C13.3168 25.8606 12.9225 25.9428 12.4378 25.9428H11.8217C11.7724 25.9428 11.7313 25.9592 11.6984 25.992C11.6656 26.0249 11.6491 26.066 11.6491 26.1153V26.8218C11.6491 26.8711 11.6656 26.9121 11.6984 26.945C12.4049 27.7008 13.325 28.7524 14.4505 30.1079C14.4834 30.1489 14.5327 30.1736 14.5902 30.1736H15.6664C15.7403 30.1736 15.7978 30.1407 15.8307 30.075C15.8635 30.0093 15.8635 29.9436 15.806 29.8861C14.7052 28.5716 13.859 27.5858 13.2429 26.9286C13.8673 26.8546 14.3766 26.6493 14.7709 26.3207C15.1653 25.992 15.4035 25.5566 15.4856 25.0227H16.414C16.4633 25.0227 16.5043 25.0062 16.5372 24.9734C16.5701 24.9405 16.5865 24.8994 16.5865 24.8501V24.2833C16.5865 24.2258 16.5701 24.1847 16.5372 24.1518Z" fill={color2} />
                <path d="M24.6785 25.8113C24.6785 24.9652 25.1796 24.2011 25.9601 23.8725L27.3402 23.281C25.6397 19.806 22.37 16.2488 19.5111 13.5378H8.94636C6.46537 15.9284 3.31073 19.2556 1.50338 22.6156C1.5116 22.6156 1.52803 22.6156 1.53624 22.6238C0.591495 24.349 0 26.0742 0 27.6762C0 34.7413 5.1345 38.9885 11.9038 39.7936C11.9038 39.7936 13.8919 40.0565 16.7755 39.7936H16.6604C20.0287 39.4157 22.9697 38.1506 25.0728 36.1378C25.0646 36.1214 25.0564 36.105 25.0482 36.0885C25.3521 35.801 25.6314 35.4971 25.9026 35.1849C25.0564 33.5254 24.6785 31.6277 24.6785 29.3439V25.8113ZM14.9599 33.0818C14.812 33.09 14.6559 33.09 14.4998 33.09C10.5483 33.09 7.39369 29.9354 7.39369 26.0249C7.39369 22.4184 10.0472 19.4774 13.4812 18.9927C13.6537 18.9763 13.8344 18.9763 14.0069 18.9763C17.9174 18.9763 21.1131 22.1309 21.1131 26.0824C21.1213 29.6725 18.4596 32.6217 14.9599 33.0818Z" fill={color2} />
                <path d="M39.474 24.998L33.3044 22.3527C33.0826 22.2542 32.8361 22.2542 32.6061 22.3527L26.4365 24.998C26.1161 25.1377 25.9025 25.4581 25.9025 25.8031V29.3275C25.9025 34.1744 27.6934 37.0087 32.5157 39.7854C32.6554 39.8594 32.8032 39.9004 32.9511 39.9004C33.099 39.9004 33.2551 39.8594 33.3865 39.7854C38.2006 37.0169 39.9998 34.1826 39.9998 29.3275V25.8031C40.008 25.4581 39.7944 25.1377 39.474 24.998ZM36.2865 28.9988L32.7622 33.4022C32.5897 33.6076 32.3432 33.7308 32.0721 33.7308C32.0639 33.7308 32.0474 33.7308 32.031 33.7308C31.7517 33.7144 31.4888 33.5747 31.3327 33.3365L29.5665 30.6912C29.2954 30.2886 29.4022 29.7382 29.8129 29.4671C30.2155 29.196 30.7659 29.3028 31.037 29.7136L32.1296 31.3566L34.8981 27.8898C35.2021 27.5119 35.7607 27.4544 36.1386 27.7501C36.533 28.0705 36.5905 28.6209 36.2865 28.9988Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_4_26">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}