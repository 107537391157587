import { Checkbox } from "@mui/material";
import IconCheckboxUnchecked from "../icons/icon-common/icon-checkbox-unchecked";
import IconCheckboxChecked from "../icons/icon-common/icon-checkbox-checked";
import { LazyImage } from "../image/lazyImage";
import { forwardRef } from "react";

// @params: Object List Parameter.
// @option: Any => Options Provided to AutoComplete.
// @state: Object => Checkbox Status.
// @ownerState: Object => The state of the Autocomplete component(As per Documentation). Undefined


export function AutocompleteOption(params, option) {
  // console.log("PARAMS: ", params);
  // console.log("OPTION: ", option);
  // console.log("STATE: ", state);
  // console.log("OWNER_STATE: ", ownerState);

  const isActive = option.status !== 0;
  return (
    <li
      {...params}
      className={`${params.className} ${isActive ? 'active' : 'in-active'}`}
      onClick={isActive ? params.onClick : undefined}
    >
      {option.label ?? option}
    </li>
  );
}

export function AutocompleteMultiOption(params, option, state) {
  // console.log("PARAMS: ", params);
  // console.log("OPTION: ", option);
  // console.log("STATE: ", state);
  // console.log("OWNER_STATE: ", ownerState);

  const isActive = option.status !== 0;

  return (
    <li
      {...params}
      className={`${params.className} ${isActive ? 'active' : 'in-active'} multiselect-option`}
      onClick={isActive ? params.onClick : undefined}
    >
      <Checkbox
        checked={state.selected}
        icon={<IconCheckboxUnchecked />}
        checkedIcon={<IconCheckboxChecked />}
      />
      {option.label ?? option}
    </li>
  )
}

export const AutocompleteImageOption = forwardRef(({ option, imgSrc, fallBackSrc, ...params }, ref) => {
  return (
    <li {...params} style={{ display: 'flex', gap: 8 }} ref={ref} key={params.key}>
      <LazyImage name={imgSrc} height={25} width={25} fallbackImage={fallBackSrc} />
      {option.label ?? option}
    </li>
  );
});
AutocompleteImageOption.displayName = 'AutocompleteImageOption';