import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { promptActions, promptContext } from "../../context/prompt";
import { useContext } from "react";

export function useRequestOTP() {
  const prompt = useContext(promptContext);
  return useMutation({
    mutationFn: (form) => axios.post("auth/sendOtp/customer", form),
    onSuccess: (response) => {
      // console.debug("SUCCESS", response);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: "success", message: response.data?.message || "OTP verified successfully" } });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Error while verifying OTP" } })
    }
  })
}

export function useResendOTP() {
  const prompt = useContext(promptContext);
  return useMutation({
    mutationFn: (form) => axios.post("auth/resendOtp/customer", form),
    onSuccess: (response) => {
      // console.debug("SUCCESS", response);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: "success", message: response.data?.message || "OTP verified successfully" } });
    },
    onError: (error) => {
      // console.debug("ERROR", error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Error while verifying OTP" } })
    }
  })
}

export function useVerifyOTP() {
  return useMutation({
    mutationFn: (form) => axios.post("auth/verifyOtp/customer", form)
  })
}

export function useAddProfile(tempToken) {
  return useMutation({
    mutationFn: (form) => axios.create({

      baseURL: process.env.REACT_APP_SERVER_URL,

      headers: { Authorization: `Bearer ${tempToken}` },
    }).post("auth/register/customer", form)
  })
}