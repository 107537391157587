import AuthContextWrapper from "./authContext";
import DatePickerContextWrapper from "./datePicker";
import ThemeContextWrapper from "./muiTheme";
import PromptContextWrapper from "./prompt";
import QueryContextWrapper from "./queryClient";
import HelmetAsync from "./reactHelmet";

export default function Context({ children }) {
  return (
    <HelmetAsync>
      <QueryContextWrapper>
        <ThemeContextWrapper>
          <PromptContextWrapper>
            <DatePickerContextWrapper>
              <AuthContextWrapper>
                {children}
              </AuthContextWrapper>
            </DatePickerContextWrapper>
          </PromptContextWrapper>
        </ThemeContextWrapper>
      </QueryContextWrapper>
    </HelmetAsync>
  )
}
