import { Avatar, Button, Drawer, Menu, MenuItem, } from "@mui/material";
import IconLogoText from "../../../assets/icons/icon-sidebar/icon-logo-text";
import IconLogoTractor from "../../../assets/icons/icon-sidebar/icon-logo-tractor";
import { MenuOnly } from "../header";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import IconHamburger from "../../../assets/icons/icon-common/icon-hamburger";
import { useAuthContext } from "../../../context/authContext";
import IconLogout from "../../../assets/icons/icon-header/icon-logout";
import LeftArrowBack from "../../../assets/icons/icon-sidebar/left-arrow";
import { useUpdateLanguage } from "./header-api";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";

export default function HeaderResponsive({
	SIDEBAR_MENU,
	languageOptions,
	isImage,
	image
}) {
	const { getData, setToken } = useAuthContext();
	const { isAuth, userData, lang } = getData();
	const [state, setState] = useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});
	const navigate = useNavigate();
	const updateLanguage = useUpdateLanguage();

	const [languageSelect, setLanguageSelect] = useState(null);
	// const [selectedLanguageId, setSelectedLanguageId] = useState(null);


	const languageMenuOpen = Boolean(languageSelect)
	const handleLanguageDrawerOpen = (event) => setLanguageSelect(event.currentTarget);
	const handleLanguageDrawerClose = () => setLanguageSelect(null);

	const handleClick = (option) => {
		if (isAuth) {
			const form = new FormData();
			form.append("language", option);
			updateLanguage.mutate(form, {
				onSuccess: (response) => {
					setToken(response.data.token);
					setLanguageSelect(null);

				},
			});
		} else {
			setToken("changeLang", option);
			setLanguageSelect(null)
		}
	};

	const selectedLanguage = languageOptions.find((option) => option.id === lang);

	const toggleDrawer = (anchor, open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const handleNavigation = (path) => {
		// handleDrawerClose();
		navigate(path);
	}

	return (
		<div className='header-home'>
			<div className='logo' onClick={() => navigate(`/`)}>
				<IconLogoTractor width='50' height='30' />
				<IconLogoText width='100' height='30' />
			</div>
			<div className="header-menu">
				<IconHamburger onClick={toggleDrawer("left", true)} color="var(--clr-primary)" width="2em" height="2em" />
				<Drawer
					anchor={"left"}
					open={state["left"]}
					onClose={toggleDrawer("left", false)}
				>

					{/* <div style={{ marginInline: 'auto' }} onClick={() => navigate(`/`)}>
						<IconLogoTractor width='50' height='30' />
						<IconLogoText width='100' height='30' />
					</div> */}
					<div style={{ width: "min(100vw,300px)", padding: '24px 10px', display: 'flex', flexDirection: 'column', gap: 38 }}>
						<div style={{ display: "flex", alignItems: 'center', gap: 5, }}>
							<div style={{ display: "flex" }} onClick={toggleDrawer("left", false)}>
								<LeftArrowBack width="20" height="20" />
							</div>
							<div onClick={toggleDrawer("left", false)}>
								<p>Back</p>
							</div>
						</div>
						{isAuth ? (<div style={{ backgroundColor: '#F2F7FF', padding: 12, }}>
							<div style={{ display: "grid", gridTemplateColumns: '1fr 2fr', gap: 12 }}>
								<div style={{ display: "flex", alignItems: 'center' }}>
									{isImage ? (
										<Avatar src={image} style={{ height: 50, width: 50 }} />
									) : (
										<Avatar style={{ backgroundColor: 'var(--clr-primary)', height: 50, width: 50 }}>{image}</Avatar>
									)}
								</div>
								<div style={{ lineHeight: '24px' }}>
									<p>{userData.first_name} {userData.last_name}</p>
									<p>{userData.phone_no}</p>
									{/* <p>{userData.email}</p> */}
								</div>

							</div>
						</div>
						) : (
							<div style={{
								display: 'inline-flex',
								gap: 10,
								alignItems: "center",
								justifyContent: "left",
								// margin: "auto auto 16px",
							}}>
								<Button
									variant='outlined'
									style={{ minWidth: 148 }}
									onClick={(event) => {
										navigate("/login");
										toggleDrawer("left", false)(event);
									}}>Login</Button>
							</div>
						)}
						{SIDEBAR_MENU.map((item, index) => {
							const shouldRender = item.protected ? isAuth : true;
							return shouldRender && <div key={index} onClick={toggleDrawer("left", false)}>
								<MenuOnly item={item} />
							</div>
						}
						)}

					</div>
					{isAuth ? (
						<div style={{
							display: 'inline-flex',
							gap: 10,
							alignItems: "center",
							justifyContent: "center",
							margin: "auto auto 16px",
						}} onClick={() => handleNavigation("/logout")}>
							<div className="menu-icon">
								<IconLogout width='2em' height='2em' />
							</div>
							<div className="menu-details">
								<div className="menu-text-header">Logout</div>
							</div>
						</div>

					) : (
						null
					)}
				</Drawer>
			</div>
			<div className="header-profile-container">
				<div className="language">
					<Button
						variant="outlined"
						onClick={languageMenuOpen ? handleLanguageDrawerClose : handleLanguageDrawerOpen}
						// startIcon={<IconStar />}
						endIcon={<ArrowDropDownIcon />}
						style={{ paddingInline: 8 }}
					>{selectedLanguage.sortLabel}
					</Button>
				</div>
				<Menu
					open={languageMenuOpen}
					anchorEl={languageSelect}
					className='profile-menu language-menu'
					onClose={handleLanguageDrawerClose}
					elevation={0}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					sx={{
						'& .MuiPaper-root': {
							borderRadius: "8px",
							boxShadow: "0px 8px 24px 0px rgba(149, 157, 165, 0.37)",
							marginTop: "10px",
							minWidth: "124px",
						},
						'& ul': {
							paddingTop: 0,
							paddingBottom: 0
						},
						'& li': {
							gap: "1em",
							paddingTop: "0.5em",
							paddingBottom: "0.5em",
							alignItems: 'center',
						},
					}}
				>
					{languageOptions.map((option) => (
						<MenuItem key={option.id} className={`language-option ${option.id === lang ? "active" : ""}`} onClick={() => { handleClick(option.id) }}>
							{option.label}
						</MenuItem>
					))}
				</Menu>
			</div>
		</div>
	)
}