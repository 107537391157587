import "./footer.css";
import "../homepage/components/cancellation-and-rescheduling-policy/cancellation-and-rescheduling-policy";
import "../homepage/components/terms-and-condition/terms-and-condition";
import "../homepage/components/privacy-policy/privacy-policy";
import "../homepage/components/service-agreement/service-agreement";
import { Link, useNavigate } from "react-router-dom";
import IconLogoTractor from "../../assets/icons/icon-sidebar/icon-logo-tractor";
import IconLogoText from "../../assets/icons/icon-sidebar/icon-logo-text";
import { useLanguage } from "../../language/useLanguage";

export default function Footer() {
  const navigate = useNavigate();
  const langData = useLanguage();
  return (
    <footer style={{ marginTop: 32 }}>
      <div className="main-footer-container">
        <div className="logo-social-container">
          <div className="logo-container">
            <div
              style={{ paddingBottom: 5 }}
              className="logo"
              onClick={() => navigate(`/`)}
            >
              <IconLogoTractor
                width="60"
                height="80"
                color1="var(--bg-secondary)"
                color2="var(--bg-secondary)"
              />
              <IconLogoText
                width="150"
                height="80"
                color1="var(--bg-secondary)"
                color2="var(--bg-secondary)"
              />
            </div>
          </div>
          <div className="social-media-container">
            <div className="abt-footer-title">
              <p>{langData("Follow Us")}</p>
            </div>
            <div className="social-media-icn-container">
              <Link
                target="_blank"
                to="https://www.facebook.com/tractorsevaindia?mibextid=LQQJ4d"
              >
                <img src="/images/footer-facebook-social-v1.1.svg" />
              </Link>
              <Link
                target="_blank"
                to="https://www.instagram.com/tractorseva/?igsh=MXBsZGk3ajkyMTA4NQ%3D%3D"
              >
                <img src="/images/footer-insta-social.svg" />
              </Link>
              <Link
                target="_blank"
                to="https://www.linkedin.com/company/tractor-seva/"
              >
                <img src="/images/footer-linkdin.svg" />
              </Link>
              {/* <Link
                target="_blank"
                to="https://www.youtube.com/channel/UCQWWxUEG7HB-JojqQsuclkQ"
              >
                <img src="/images/footer-youtube-social.svg" />
              </Link> */}
              <Link
                target="_blank"
                to="https://twitter.com/TractorSeva"
              >
                <img src="/images/twitter.svg" />
              </Link>
            </div>
          </div>
        </div>
        <div className="links-Container">
          <div className="help-info-footer-links">
            <div className="help-info-link-footer-title">
              <p>{langData("Important Links")}</p>
            </div>
            <li>
              <Link to="terms-and-condition">{langData("Terms and Condition")}</Link>
            </li>
            <li>
              <Link to="privacy-policy">{langData("Privacy Policy")}</Link>
            </li>
            <li>
              <Link to="service-agreement">{langData("Service Agreement")}</Link>
            </li>
            <li>
              <Link to="cancellation-policy">
                {langData("Cancellation and Rescheduling Policy")}
              </Link>
            </li>
          </div>
          <div className="help-info-link-container">
            <div className="help-info-link-footer-title">
              <p>{langData("Contact Us")}</p>
            </div>
            <div className="help-info-footer-links">
              <div className="location-container">
                <div className="location-icn-container">
                  <img
                    className="location-icn"
                    src="/images/footer-location-pin.svg"
                  />
                </div>
                <div className="location-details">
                  <p dangerouslySetInnerHTML={{ __html: langData("Bedienung Solution Private Limited (Tractor Seva)<br /> C/o- InFED Indian Institute of Management Nagpur Mihan (Non-Sez), Nagpur, Maharashtra, India - 441108") }}>
                  </p>
                </div>
              </div>
              <div className="mail-container">
                <div className="mail-icn-container">
                  <img className="mail-icn" src="/images/footer-mail.svg" />
                </div>
                <div className="mail-details">
                  <Link
                    to="mailto:customercare@tractorseva.com"
                    target="_blank"
                  >
                    customercare@tractorseva.com
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ask-container">
          <div className="ask-title">
            <p>
              {langData("Have a Question?")} <br />
              {langData("Feel free to ask")}
            </p>
          </div>
          {/* <div className="ask-subtitle"> */}
          {/* <p>
              Pay with ease using UPI, credit card, or debit card, offering{" "}
              <br />
              you multiple secure and convenient payment
            </p> */}
          {/* </div> */}
          {/* <div className="contact-container">
            <div className="ph-icn-container">
              <img className="ph-icn" src="/images/ph-icn-footer.svg" />
            </div>
            <div className="ask-phone-no">
              <Link className="" to="tel:+91-92093-92096" target="_blank">
                +91 92093 92096
              </Link>
            </div>
          </div> */}
          <div className="contact-container">
            <img className="ph-icn" src="/images/ph-icn-footer.svg" />
            <Link to="tel:+91-92093-92096" target="_blank">
              {langData("+91 92093 92096")}
            </Link>
          </div>
        </div>
      </div>
      <div className="sub-footer-container">
        <div className="copyright">{langData("All rights reserved to Tractor seva")}</div>
        <div className="pc-container">
          <img src="/icons/UPI.svg" />
          <img src="/icons/RuPay 1.svg" />
          <img src="/icons/MasterCard.svg" />
          <img src="/icons/Visa&mastercard.svg" />
          <img src="/icons/AmericanExpress.svg" />
          {/* <img src="/icons/NET BANKING.svg" /> */}
        </div>
      </div>
    </footer>
  );
}
