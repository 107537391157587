import { Outlet, useLocation } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import { useEffect } from "react";
import ReactGA from 'react-ga';
ReactGA.initialize("G-M10D9W5CJQ");

export default function Layout() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <main className="layout">
      <Header />
      <section>
        <Outlet />
      </section>
      <Footer />
    </main>
  )
}
