import { Helmet } from 'react-helmet-async'

export default function ContactUsSEO() {
  return (
    <Helmet>
      <title>Contact Us | Tractor Seva</title>
      <meta
        name="title"
        content="Get in Touch with TractorSeva: Contact Us Now!"
      />
      <meta
        name="description"
        content="Contact us for affordable tractor servicing at TractorSeva. Expert solutions tailored to your needs. Get efficient, cost-effective maintenance today!"
      />

      {/* Open Graph Meta Tags */}
      {/* <meta property="og:title" content="Contact Us - Tractor Seva" />
      <meta property="og:description" content="Contact us to get in touch with our team. We are here to help and answer any questions you may have." /> */}
      <meta property="og:url" content="https://tractorseva.com/contact-us" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://tractorseva.com/images/contact-us-customers.jpg" />

    </Helmet>
  )
}