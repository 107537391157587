import { useState } from "react";
import PersonalDetails from "./components/personal-details";
import MyAddress from "./components/my-address";
import MyTractor from "./components/my-tractor";
import { Avatar, Badge, Button, Dialog, Skeleton } from "@mui/material";
import IconLocation from "../../assets/icons/icon-common/icon-location";
import IconTractorDualColor from "../../assets/icons/icon-common/icon-tractor-dual-color";
import IconUser from "../../assets/icons/icon-common/icon-user";
// import IconLogout from "../../assets/icons/icon-header/icon-logout";
import { useNavigate } from "react-router";
import { useAuthContext } from "../../context/authContext";
import IconLogoutProfile from "../../assets/icons/icon-common/icon-logout-profile";
import IconEdit from "../../assets/icons/icon-common/icon-edit";
import UploadDoc from "../../assets/uploadDoc/uploadDoc";
import { useGetProfile, useUpdateProfileImage } from "./profile-api";
import { useLanguage } from "../../language/useLanguage";

const ProfileButton = [
  { id: "PD", label: "Personal Details", image: <IconUser width="16px" /> },
  { id: "MA", label: "My Address", image: <IconLocation width="16px" /> },
  {
    id: "MT",
    label: "My Tractor",
    image: <IconTractorDualColor width="16px" />,
  },
];
export default function Profile() {
  const { getData } = useAuthContext();
  const { userData } = getData();
  const langData = useLanguage();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState("PD");
  const [imagePopup, setImagePopup] = useState(false);
  const { data, isError, isLoading } = useGetProfile();
  if (isError) return (<p>Error</p>);
  const image = !isLoading && data.image ? data.image : `${userData.first_name[0]}${userData.last_name[0]}`;

  return (
    <div className="profile-container">
      <h1>{langData("My Profile")}</h1>
      <div>
        <div className="profile-menu">
          <Badge
            overlap="circular"
            badgeContent={
              <Avatar
                variant="circular"
                style={{
                  height: 32,
                  width: 32,
                  fontSize: 16,
                  backgroundColor: "var(--bg-blue)",
                  cursor: "pointer",
                }}
                onClick={() => setImagePopup(true)}
              >
                <IconEdit />
              </Avatar>}
          >
            {data?.image ? (
              <Avatar src={image} />
            ) : (
              <Avatar>{image}</Avatar>
            )}
          </Badge>
          <p className="user-name">{`${userData.first_name} ${userData.last_name}`}</p>
          {imagePopup &&
            <UpdateCustomerImage
              open={imagePopup}
              handleClose={() => setImagePopup(false)}
              currentImage={data.image}
            />
          }
          {ProfileButton.map((obj) => (
            <Button
              key={obj.id}
              className={`${currentPage === obj.id ? "Active" : ""}`}
              onClick={() => setCurrentPage(obj.id)}
              startIcon={obj.image}
            >
              {langData(obj.label)}
            </Button>
          ))}
          <Button
            onClick={() => navigate("/logout")}
            startIcon={<IconLogoutProfile />}
          >
            {langData("Logout")}
          </Button>
        </div>
        <div className="profile-details">
          {isLoading ? (
            <LoadingPersonalDetails />
          ) : (
            <>
              {currentPage === "PD" && <PersonalDetails data={data} />}
              {currentPage === "MA" && <MyAddress />}
              {currentPage === "MT" && <MyTractor />}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function UpdateCustomerImage({
  open,
  handleClose,
  currentImage,
}) {
  const [uploadedCustomerImg, setUploadedCustomerImg] = useState(currentImage ? [currentImage] : []);
  const [newImg, setNewImg] = useState([]);
  const [error, setError] = useState("");
  const updateImage = useUpdateProfileImage();
  const handleUpdate = () => {
    if (newImg.length === 0 && !currentImage) {
      // No Old Image No New Image
      setError("Please Upload Image");
      return;
    }
    const form = new FormData();
    newImg.forEach((img) => form.append("files", img));
    if (currentImage && uploadedCustomerImg.length === 0) {
      form.append("dltFile", currentImage);
    }
    updateImage.mutate(form, {
      onSuccess: () => {
        handleClose();
      },
    });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <div
        style={{
          padding: "2rem",
        }}
      >
        {/* <h2 style={{ marginBottom: "0.5rem" }}>Change Profile</h2> */}
        <div className="field-container">
          <label className="required">Upload Profile Photo</label>
          <UploadDoc
            uploadedFiles={uploadedCustomerImg}
            onRemoveUploaded={() => setUploadedCustomerImg([])}
            files={newImg}
            onUpload={(files) => {
              setNewImg([...files]);
              setError("");
            }}
            onRemove={() => setNewImg([])}
            isError={Boolean(error)}
            acceptedFileFormat={["jpg", "jpeg", "png"]}
          />
          <p className="form-error-text">{error}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
          }}
        >
          <Button variant="contained" onClick={handleUpdate}>Update</Button>
          <Button variant="outlined" color="secondary" onClick={handleClose}>Cancel</Button>
        </div>
      </div>
    </Dialog>
  )
}

function LoadingPersonalDetails() {
  return (
    <div className="personal-details">
      <div className="heading-container">
        <h2>Personal Details</h2>
      </div>
      <div className="personal-details-grid">
        <p className="grid-title">First Name</p>
        <p><Skeleton variant='text' /></p>
        <p className="grid-title">Last Name</p>
        <p><Skeleton variant='text' /></p>
        <p className="grid-title">Phone Number</p>
        <p><Skeleton variant='text' /></p>
        <p className="grid-title">Gender</p>
        <p><Skeleton variant='text' /></p>
        <p className="grid-title">Email</p>
        <p><Skeleton variant='text' /></p>
        <p className="grid-title">D.O.B.</p>
        <p><Skeleton variant='text' /></p>
      </div>
    </div>
  )
}