import ContactUsInformation from "./contace-us-information/contact-us-information";
import ContactUsBanner from "./contact-us-banner/contact-us-banner";
import ContactUsForm from "./contact-us-form/contact-us-form";
import ContactUsSEO from "./contact-us-seo";

export default function ContactUs() {
  return (
    <div>
      <ContactUsSEO />
      <ContactUsBanner />
      <ContactUsInformation />
      <ContactUsForm />
    </div>
  );
}
