export default function IconPlusRound({
  width = "1em",
  height = "1em",
  color = "currentColor",
  ...props
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12.5" cy="12.5" r="12.5" fill={color} />
      <g clipPath="url(#clip0_757_1035)">
        <path d="M12 8.5V17.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5 13H16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_757_1035">
          <rect width="18" height="18" fill="white" transform="translate(3 4)" />
        </clipPath>
      </defs>
    </svg>
  )
}