import { useForm } from "react-hook-form"
import { useResendOTP, useVerifyOTP } from "../login-api";
import { Button, TextField } from "@mui/material";
import IconReload from "../../../assets/icons/icon-common/icon-reload";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/authContext";
import { useEffect, useState } from "react";
import { useLanguage } from "../../../language/useLanguage";

const defaultValues = {
  otp: '',
  otp1: "",
  otp2: "",
  otp3: "",
  otp4: "",
}

export default function FormVerifyOTP({
  phone_no,
  getProfileForm,
  resendError,
}) {
  const navigate = useNavigate();
  const { setToken } = useAuthContext();
  const langData = useLanguage();
  // const resendOTP = useResendOTP();
  const verifyOTP = useVerifyOTP();
  const location = useLocation();

  const { handleSubmit, formState: { errors }, setError, register, setFocus, setValue, watch } = useForm({ defaultValues: defaultValues });

  function submit(inputs) {
    console.debug('SUBMITTING: ', inputs);

    const form = new FormData();
    form.append("phone_no", phone_no)
    form.append("otp", inputs.otp1 + inputs.otp2 + inputs.otp3 + inputs.otp4)

    verifyOTP.mutate(form, {
      onSuccess: (response, form) => {
        console.debug('VERIFY_SUCCESS: ', { response, form });
        if (response.data.profile_complete) {
          setToken(response.data.token);
          if (location?.state?.redirectTo) {
            navigate(location?.state?.redirectTo, { state: location.state });
          } else {
            navigate("/");
          }
        }
        else {
          getProfileForm(response.data.token);
        }
      },
      onError: (err, form) => {
        console.debug('VERIFY_ERROR: ', { err, form });
        const error = err.response.data?.message || "OTP did not match";
        setError("root.serverError", { message: error, type: 500 });
      },
    })
  }

  const handleKeyDown = (fieldName, event, oldValue) => {
    if (event.key === 'Backspace' && oldValue === '') setFocus(fieldName);
  }

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData('text');
    setValue("otp1", pasteData[0]);
    setValue("otp2", pasteData[1]);
    setValue("otp3", pasteData[2]);
    setValue("otp4", pasteData[3]);
    setFocus('otp4');
  }

  return (
    <form className="login-form" noValidate onSubmit={handleSubmit(submit)}>
      <p className="title">{langData("Verification Code")}</p>
      <p className="error-text">{errors.root?.serverError?.message ?? langData("SMS sent on your Mobile Number")}</p>
      <div className="field-input otp-field">
        <TextField
          className="login-field-otp"
          inputProps={{ maxLength: 1, style: { textAlign: 'center' }, inputMode: 'numeric', pattern: '[0-9]*' }}
          error={!!errors.otp1 || !!errors.otp2 || !!errors.otp3 || !!errors.otp4}
          onPaste={handlePaste}
          autoFocus  
          {...register("otp1", {
            required: langData('Field is Required'),
            onChange: (e) => {
              if (e.target.value) {
                setFocus('otp2');
              }
            }
          })}
        />
        <TextField
          className="login-field-otp"
          inputProps={{ maxLength: 1, style: { textAlign: 'center' }, inputMode: 'numeric', pattern: '[0-9]*' }}
          error={!!errors.otp1 || !!errors.otp2 || !!errors.otp3 || !!errors.otp4}
          onKeyDown={(event) => handleKeyDown('otp1', event, watch('otp2'))}
          {...register("otp2", {
            required: langData('Field is Required'),
            onChange: (e) => {
              if (e.target.value) {
                setFocus('otp3');
              }
            }
          })}
        />
        <TextField
          className="login-field-otp"
          inputProps={{ maxLength: 1, style: { textAlign: 'center' }, inputMode: 'numeric', pattern: '[0-9]*' }}
          error={!!errors.otp1 || !!errors.otp2 || !!errors.otp3 || !!errors.otp4}
          onKeyDown={(event) => handleKeyDown('otp2', event, watch('otp3'))}
          {...register("otp3", {
            required: langData('Field is Required'),
            onChange: (e) => {
              if (e.target.value) {
                setFocus('otp4');
              }
            }
          })}
        />
        <TextField
          className="login-field-otp"
          inputProps={{ maxLength: 1, style: { textAlign: 'center' }, inputMode: 'numeric', pattern: '[0-9]*' }}
          error={!!errors.otp1 || !!errors.otp2 || !!errors.otp3 || !!errors.otp4}
          onKeyDown={(event) => handleKeyDown('otp3', event, watch('otp4'))}
          {...register("otp4", {
            required: langData('Field is Required'),
            onChange: (e) => {
              if (e.target.value) {
                setFocus('otp4');
              }
            }
          })}
        />
      </div>
      <div className="resend-info" style={{ marginTop: "-12px", marginBottom: "12px", justifyContent: "end" }}>
        <p> <Timer /> </p>
      </div>
      <Button variant="contained" type="submit" disabled={verifyOTP.isLoading}>{langData("Proceed")}</Button>

      <div className="resend-info">
        <IconReload width="11px" height="11px" />
        <p>{langData("Not received code?")}</p>
        <ResendCodeTimer
          phone_no={phone_no}
          resendError={resendError}
        />
      </div>
    </form>
  )
}

function Timer() {
  const [seconds, setSeconds] = useState(900);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => prev - 1);
    }, 1000)
    return () => clearInterval(interval);
  }, []);
  return (
    <p> {minutes < 10 ? `0${minutes}` : minutes} : {remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}</p>
  )
}

function ResendCodeTimer({
  phone_no,
  resendError,
}) {
  const resendOTP = useResendOTP();
  const [seconds, setSeconds] = useState(10);
  const langData = useLanguage();
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => prev > 0 ? prev - 1 : 0);
    }, 1000)
    return () => clearInterval(interval);
  }, []);

  function handleResendOtp() {
    console.debug("SUBMITTING...RESEND OTP");
    const form = new FormData();
    form.append('phone_no', phone_no);
    resendOTP.mutate(form, {
      onSuccess: () => {
        setSeconds(10);
      },
      onError: () => {
        resendError();
      }
    });
  }

  return (
    <>
      <Button
        className={`resend-otp-btn ${seconds ? "disable" : ""}`}
        variant="text"
        color="secondary"
        onClick={handleResendOtp}
        disabled={Boolean(seconds)}
        sx={{
          '&.MuiButton-root.MuiButtonBase-root': {
            fontSize: 16,
            px: 1,
            ":hover": {
              backgroundColor: "transparent",
            }
          }
        }}
      >
        {langData("Resend code")}
      </Button>
      {/* {Boolean(seconds) && <span
        style={{
          fontSize: "1rem",
          color: "var(--clr-gray)",
        }}
      >
        in {seconds < 10 ? `0${seconds}` : seconds} seconds
      </span>} */}
    </>
  )
}