import { Button } from "@mui/material";
import "./Join-our-franchise.css";
import { useLanguage } from "../../../../../language/useLanguage";

export default function JoinOurFranchise() {
  const langData = useLanguage();
  return (
    <div className="join-our-franchise-program-container">
      <div className="join-our-franchise-program-img-container">
        <img
          className="join-our-franchise-program-img"
          src="./images/join-our-franchise-sec-left-new.jpg"
        // style={{ backgroundImage: 'url(./images/join-our-franchise-sec-left-new.jpg)' }}
        />
      </div>
      <div className="join-our-franchise-program-des-container">
        <div className="join-our-franchise-program-des">
          <div className="join-our-franchise-head-container">
            <h5>{langData("Join Our Franchise")}</h5>
            <p>
              {langData(`At Tractor Seva, we invite you to become a part of a renowned premium brand that has been reshaping the tractor service industry for many years.`)}
            </p>
          </div>
          <div className="join-our-franchise-des-container">
            <p>
              {langData(`Recognized for our extensive experience and innovation, we offer a versatile and comprehensive range of tractor servicing solutions. Our aim is to set new benchmarks in the industry by providing an unmatched experience and robust support to our customers.`)}
            </p>
            <p>
              {langData(`In a market flooded with tractors, the tractor servicing landscape has long been lacking in expert consultation, personalized assistance, and reliable maintenance services. But were here to change that narrative, and you can be part of it too. When you join our franchisee network, you become a vital part of our prestigious group of like-minded individuals dedicated to revolutionizing the service industry and being part of the solution.`)}
            </p>
          </div>
          <Button
            variant="contained"
            sx={{ mt: 3 }}
            href="#franchise-form"
          >
            {langData("Enquire Now")}
          </Button>
        </div>
      </div>
    </div>
  );
}
