import { Button, InputAdornment, TextField } from "@mui/material";
import "./contact-us-form.css";
import { Controller, useForm } from "react-hook-form";
import InputNumber from "../../../../../assets/custom-fields/input-number";
import { Regex_email } from "../../../../../config/validation/regex";
import { useAddContactInquiry } from "../../franchise/franchise-form/franchise-api";
import { useLanguage } from "../../../../../language/useLanguage";

const defaultValues = {
  name: "",
  phone_no: "",
  email: "",
  message: "",
};

export default function ContactUsForm() {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues: defaultValues });
  const addContactInquiry = useAddContactInquiry();

  const submit = (inputs) => {
    console.debug(inputs);
    const form = new FormData();
    for (const key in inputs) { form.append(key, inputs[key]) }
    addContactInquiry.mutate(form, {
      onSuccess: () => {
        reset();
      }
    })
  }

  const langData = useLanguage();

  return (
    <div className="contact-us-container">
      <div className="contact-us-des-container">
        <div className="contact-us-title">
          <p>
            {langData("Discover convenience, reliability, and value like never before. Join Tractor Seva today and experience the difference!")}
          </p>
          <img
            className="contact-us-img"
            src="./images/contact-us-customers.jpg"
          />
        </div>
      </div>
      <div className="contact-us-form-container">
        <form className="form-container" noValidate onSubmit={handleSubmit(submit)}>
          <div className="column-two">
            <div className="field-container">
              <label className="required">{langData("Name")}</label>
              <TextField
                {...register("name", {
                  required: langData("Field is required"),
                  minLength: {
                    value: 2,
                    message: "Minimum 2 character required",
                  },
                  maxLength: { value: 30, message: "Maximum limit exceed" },
                })}
                inputProps={{
                  maxLength: 30
                }}
                error={!!errors.name}
              />
              <p className="error-text">{errors.name?.message}</p>
            </div>
          </div>
          <div className="column-two">
            <div className="field-container">
              <label className="required">{langData("Phone Number")}</label>
              <Controller
                name="phone_no"
                control={control}
                rules={{
                  required: langData("Phone Number is Required"),
                  maxLength: { value: 10, message: "Enter Valid Number" },
                  minLength: { value: 10, message: "Enter Valid Number" },
                }}
                render={({ field }) => (
                  <InputNumber
                    {...field}
                    fullWidth
                    error={!!errors.phone_no}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+91</InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: 10 }}
                  />
                )}
              />
              <p className="error-text">{errors.phone_no?.message}</p>
            </div>
          </div>
          <div className="column-two">
            <div className="field-container">
              <label>{langData("Email")}</label>
              <TextField
                {...register("email", {
                  minLength: { value: 2, message: "Minimum 2 character required" },
                  maxLength: { value: 50, message: "Maximum limit exceed" },
                  pattern: { value: Regex_email, message: "Invalid Email ID" },
                })}
                error={!!errors.email}
                inputProps={{
                  maxLength: 50
                }}
              />
              <p className="error-text">{errors.email?.message}</p>
            </div>
          </div>
          <div className="column-two">
            <div className="field-container">
              <label className="">{langData("Message")}</label>
              <TextField
                multiline
                rows={3}
                {...register("message", {
                  maxLength: { value: 500, message: "Maximum limit exceed" },
                })}
                inputProps={{
                  maxLength: 500
                }}
              />
              <p className="error-text">{errors.message?.message}</p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              gap: 16,
              flexWrap: "wrap",
            }}
          >
            <Button variant="contained" type="submit">
              {langData("Submit")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
