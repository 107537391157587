import "./warranty-bosch-t&c.css";



export default function BoschTermsandcondition() {
    return (
        <>
            <div className="bosch_terms_main">
                <div className="bosch_container">
                    <h1 className="bosch_heading_text" style={{ margin: "20px 0" }}>Extended Warranty Terms And Conditions</h1>
                    <h2 className="bosch_second_heading">Extended Warranty Start</h2>
                    <p className="p_text">
                        <ul>
                            <li>
                                <p>
                                    After completion of standard warranty, which is 18 months from Pump manufacturing
                                    date and 1 month after Extended Warranty Details of Tractors to which Extended Warranty is
                                    purchase, whichever is later.

                                </p>
                            </li>
                        </ul>
                    </p>
                    <h2 className="bosch_second_heading">Extended Warranty Period</h2>
                    <p className="p_text">
                        <ul>
                            <li>
                                <p>
                                    12 months
                                </p>
                            </li>
                        </ul>
                    </p>
                    <h2 className="bosch_second_heading">Sales Channel</h2>
                    <p className="p_text">
                        <ul>
                            <li>
                                <p>
                                    Via M/s Tractor Seva
                                </p>
                            </li>
                        </ul>
                    </p>
                    <h2 className="bosch_second_heading">Coverage </h2>
                    <p className="p_text">
                        <ul>
                            <li>
                                <p>
                                    One time Element, DV & Nozzle for 3 & 4 Cyl.
                                    Pumps during the EW period is included.
                                    Decision on part replacement is as guided
                                    by Bosch to their Service partners based on
                                    functional testing & part check.
                                </p>
                            </li>
                            <li>
                                <p>
                                    For parts not covered under EW, the end customer to
                                    pay for spares and related labour if any.
                                </p>
                            </li>
                            <li>
                                <p>
                                    This is valid only for pumps & Tractors
                                    whose information as mentioned in the
                                    adjacent box are shared with Bosch.
                                </p>
                            </li>
                        </ul>
                    </p>
                    <h2 className="bosch_second_heading">Warranty Criteria</h2>
                    <p className="p_text">
                        <ul>
                            <li>
                                <p>
                                    All failures to Element, DV and Nozzle, except- Rust,
                                    effects of unprescribed fuel and Pump found tampered.
                                </p>
                            </li>
                        </ul>
                    </p>
                    <h2 className="bosch_second_heading">Claim Process</h2>
                    <p className="p_text">
                        <ul>
                            <li>
                                <p>
                                    Suspected pumps and NHA to be handed over to the warranty
                                    handling Authorized Bosch Dealers(whose list will be shared from time to time),
                                    along with referral form from Tractor Seva Franchise + Proof of Extended Warranty purchase.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Bosch dealer will raise an Electronic Job Card (EJC) and share with the OECD
                                </p>
                            </li>
                        </ul>
                    </p>
                </div>
                <div>
                    <p style={{ fontSize: "12px" }}>
                        Warranty to be given by Bosch
                        Automative Service Solutions.
                    </p>
                </div>
            </div>
        </>
    )
}