export default function IconCheckboxChecked({
  width = "1em",
  height = "1em",
  ...props
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="19" height="19" rx="1.15213" fill="#EC2726" />
      <path d="M13.3405 6.46777L8.21995 11.3188L5.65967 8.89331" stroke="white" strokeWidth="1.61702" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
