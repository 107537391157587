export default function IconLocation({
  width = "1em",
  height = "1em",
  color = "var(--clr-primary)",
  color2 = "var(--clr-secondary)",
  ...props
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 60 61" fill="none" {...props}>
      <path d="M30.334 1.24023C20.2043 1.24023 11.7557 9.40003 11.7557 19.8185C11.7557 23.7821 12.9468 27.3165 15.2326 30.6283L28.9122 51.9742C29.5759 53.0119 31.0935 53.0098 31.7557 51.9742L45.4948 30.5557C47.7313 27.3939 48.9122 23.6813 48.9122 19.8185C48.9122 9.57444 40.578 1.24023 30.334 1.24023ZM30.334 28.2632C25.6778 28.2632 21.8893 24.4747 21.8893 19.8185C21.8893 15.1623 25.6778 11.3738 30.334 11.3738C34.9902 11.3738 38.7787 15.1623 38.7787 19.8185C38.7787 24.4747 34.9902 28.2632 30.334 28.2632Z" fill={color2} />
      <path d="M43.5374 40.0527L35.0329 53.3488C32.8316 56.7809 27.8241 56.7697 25.6333 53.3521L17.115 40.0562C9.62024 41.7891 5 44.9635 5 48.7569C5 55.3395 18.053 58.8905 30.334 58.8905C42.615 58.8905 55.668 55.3395 55.668 48.7569C55.668 44.9608 51.0413 41.7847 43.5374 40.0527Z" fill={color} />
    </svg>
  )
}