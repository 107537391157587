import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { promptActions, promptContext } from "../../context/prompt";
import useAxios from "../../config/axios/useAxios";
import { useAuthContext } from "../../context/authContext";

export function useGetCustomerAddressDDList(customer_id) {
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);

  const API = 'getdata/customer_address';
  return useQuery({
    queryKey: [API],
    enabled: !!customer_id,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${customer_id}`);
        data.forEach((obj) => obj.label = `${obj.address_1}, ${obj.address_2}, ${obj.SubDistrict.subdistrict_name}, ${obj.SubDistrict.District.district_name}, ${obj.SubDistrict.District.State.state_name} - ${obj.pincode}`)
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken("clearToken");
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Token Expired" } })
        }
        return;
      }
    }
  })
}

export function useGetBookingWorkshop(customer_address_id, service_id) {
  const API = "booking/getall/workshops"
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);

  return useQuery({
    queryKey: [API, customer_address_id, service_id],
    enabled: !!customer_address_id && !!service_id,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${customer_address_id}/${service_id}`);
        data.forEach((obj) => obj.label = `${obj.address_1}, ${obj.address_2}, ${obj.SubDistrict.subdistrict_name}, ${obj.SubDistrict.District.district_name}, ${obj.SubDistrict.District.State.state_name} - ${obj.pincode}`)
        return data.filter(obj => obj.status === 1);
      } catch (error) {
        if (error.response.status === 401) {
          setToken("clearToken");
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Token Expired" } });
        }
        return;
      }
    }
  })
}

export function useGetBookingWorkshopTimings(workshop_id) {
  const API = "booking/get/holiday"
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  // console.debug({ workshop_id })

  return useQuery({
    queryKey: [API, workshop_id],
    enabled: !!workshop_id,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${workshop_id}`);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken("clearToken");
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Token Expired" } });
        }
        return;
      }
    }
  })
}

export function useGetPreBookingSummary() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: (form) => protectedAxios.post("/booking/getsummary", form),
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Error while adding" } })
    }
  })
}

export function useAddBooking() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  // const API = "booking/add";
  const API = "customer-booking";
  return useMutation({
    mutationFn: (form) => protectedAxios.post(API, form),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: "success", message: response.data?.message || "Added successfully" } });
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Error while adding" } })
    }
  })
}

export function useGetBookings(customer_id) {
  const API = "booking/v2/customer"
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);

  return useQuery({
    queryKey: [API, customer_id],
    enabled: !!customer_id,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${customer_id}`);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken("clearToken");
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Token Expired" } });
        }
        return;
      }
    }
  })
}

export function useGetPrePayment() {
  const API = "booking/pre-payment/";
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);

  return useQuery({
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}`);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken("clearToken");
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Token Expired" } });
        }
        return;
      }
    }
  })
}

export function useGetBooking(booking_id) {
  const API = "booking/v4/get"
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);

  return useQuery({
    queryKey: [API, booking_id],
    enabled: !!booking_id,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${booking_id}`);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken("clearToken");
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Token Expired" } });
        }
        return;
      }
    }
  })
}


export function useBookingCancel(booking_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => protectedAxios.put(`/customer/booking/cancel/${booking_id}`),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: "success", message: response.data?.message || "Updated successfully" } });
      queryClient.invalidateQueries(["booking/get", booking_id]);
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Error while updating" } })
    }
  })
}

export function useRescheduleBooking(booking_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.put(`/booking/workshop/reschedule/${booking_id}`, form),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: "success", message: response.data?.message || "Updated successfully" } });
      queryClient.invalidateQueries(["booking/get", booking_id]);
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Error while updating" } })
    }
  })
}

export function useServiceLocation(params) {
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);

  return useQuery({
    queryKey: ["service-location", params],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`booking/service-location/`);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken("clearToken");
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Token Expired" } });
        }
        return;
      }
    },
  });
}

export function useUpdateCustomerFeedback(booking_id) {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.post(`/customer/feedback/${booking_id}`, form),
    onSuccess: (response) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { type: "success", message: response.data?.message || "Updated successfully" } });
      queryClient.invalidateQueries(["booking/get", booking_id.toString()]);
    },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Error while updating" } })
    }
  })
}