
import "./contact-us-information.css";
import { useLanguage } from "../../../../../language/useLanguage";

export default function ContactUsInformation() {
    const langData = useLanguage();
    return (
        <div className="maindiv">
            <div className="cantiner">
                <div className="icon">
                    <img src="images\location.svg" />
                </div>
                <div className="infocantain">
                    <p>{langData("Address")}</p>
                    {/* <p>Tractor Seva Plot no-12 Opp.</p>
                    <p>Durga Mandir Rahate Layout</p>
                    <p>Ranapratap Nagar Nagpur - 440022</p> */}
                    <p dangerouslySetInnerHTML={{ __html: langData("Bedienung Solution Private Limited (Tractor Seva)<br /> C/o- InFED Indian Institute of Management Nagpur<br />Mihan (Non-Sez), <br />Nagpur, Maharashtra, India - 441108") }}></p>
                </div>
            </div>
            <span className="line"></span>
            <div className="cantiner">
                <div className="icon">
                    <span>
                        <img src="images\contact.svg" />
                    </span>
                </div>
                <div className="infocantain">
                    <p>{langData("Customer Support")}</p>
                    <p>+91 92093 92096</p>
                    <p>customercare@tractorseva.com</p>

                </div>
            </div>

            <span className="line"></span>

            <div className="cantiner">
                <div className="icon">
                    <img src="images\inquri 469317.svg" />
                </div>
                <div className="infocantain">
                    <p>{langData("Franchise Inquiry")}</p>
                    <p>+91 92093 92096</p>
                    <p>customercare@tractorseva.com</p>
                </div>

            </div>


        </div>

    );
}