export default function IconDate({
    width = "0.8em",
    height = "0.8em",
    color = "var(--clr-primary)",
    color2 = "var(--clr-secondary)",
    ...props
}) {
    return (
        <svg width={width} height={height} viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M58.9989 27.3979V28.172C58.9989 35.5369 58.9989 42.9018 58.9989 50.2668C58.9989 54.5795 56.3748 58.0329 52.3533 58.9955C51.602 59.1617 50.8342 59.2414 50.0649 59.233C36.7046 59.2451 23.3431 59.2451 9.98041 59.233C5.41628 59.233 1.91379 56.3162 1.14071 51.8911C1.06657 51.398 1.035 50.8994 1.04634 50.4009C1.03908 42.9441 1.03908 35.4885 1.04634 28.0342C1.04634 27.8348 1.06449 27.6354 1.07537 27.3906L58.9989 27.3979Z" fill={color2} />
            <path d="M47.4141 9.94552C48.3995 9.94552 49.2797 9.94552 50.1598 9.94552C55.2412 9.97997 58.9633 13.7108 58.9977 18.8067C58.9977 19.6877 58.9977 20.5669 58.9977 21.4933H1.10686C0.769312 17.9002 1.09597 14.5374 4.08851 12.014C5.55302 10.7776 7.23349 10.0543 9.15351 9.97272C10.2787 9.92377 11.4074 9.96365 12.6197 9.96365V7.05225C12.6197 6.12408 12.6197 5.19409 12.6197 4.26592C12.6324 2.54192 13.8229 1.27475 15.4489 1.24574C17.1239 1.21674 18.3816 2.4404 18.4179 4.19884C18.4523 5.83039 18.4269 7.46194 18.4269 9.09349V9.88933H41.6051V8.31398C41.6051 6.95435 41.5869 5.59473 41.6051 4.2351C41.6359 2.4694 42.8663 1.22943 44.5341 1.24031C46.2019 1.25118 47.3996 2.52016 47.4069 4.28586C47.4214 6.14764 47.4141 8.00217 47.4141 9.94552Z" fill={color} />
        </svg>
    );
}