import { createContext, useContext, useState } from "react";
import jwt_decode from "jwt-decode";

const AuthContext = createContext(null);

export default function AuthContextWrapper({ children }) {

  const exist_token = localStorage.getItem('token');
  const exist_userdata = exist_token ? JSON.parse(localStorage.getItem('userdata')) : null;
  const exist_isAuth = exist_token && exist_userdata ? true : false;
  const exist_lang = exist_token && exist_userdata ? exist_userdata.language : "EN";

  const [token, setToken] = useState(exist_token);
  const [userData, setUserData] = useState(exist_userdata);
  const [isAuth, setIsAuth] = useState(exist_isAuth);
  const [lang, setLang] = useState(exist_lang);

  function getData() {
    if (token && userData && isAuth) {
      return { token, userData, isAuth, lang }
    } else {
      const exist_token = localStorage.getItem('token');
      if (exist_token) {
        return updateToken(exist_token);
      } else {
        return { isAuth: false, token: null, userData: null, lang }
      }
    }
  }

  function updateToken(tokenArg, langArg) {
    if (!tokenArg) {
      throw new Error(`Invalid Token Passed to setToken function, Got Token: ${tokenArg}`)
    }
    if (tokenArg === "clearToken") {
      setToken(null);
      setUserData(null);
      setIsAuth(true);
      localStorage.removeItem('token');
      localStorage.removeItem('userdata');
      return { isAuth: false, token: null, userData: null, lang };
    } else if (tokenArg === "changeLang") {
      setLang(langArg);
      return { token, userData, isAuth, lang: langArg };
    } else {
      const userdata = jwt_decode(tokenArg);
      setToken(tokenArg);
      setUserData(userdata);
      setLang(userdata.language);
      setIsAuth(true);
      localStorage.setItem('token', tokenArg);
      localStorage.setItem('userdata', JSON.stringify(userdata));
      return { token: tokenArg, userData: userdata, isAuth: true, lang: userdata.language };
    }
  }
  return (
    <AuthContext.Provider value={{ getData, setToken: updateToken }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuthContext() {
  const context = useContext(AuthContext)
  if (!context) throw new Error("Use this Context inside Auth Context Only");
  return context;
}