export default function IconQuality({
  width = "1em",
  height = "1em",
  color = "var(--clr-primary)",
  color2 = "var(--clr-secondary)",
  ...props
}) {
  return (
    <svg
      className="hoverable"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 74 74"
      fill="none"
      {...props}
    >
      <path
        d="M58.8526 21.9004L47.2461 20.2139L42.0554 9.69672C40.0514 5.6354 34.2541 5.63389 32.2491 9.69658L27.0584 20.2139L15.4518 21.9004C10.9701 22.5516 9.17752 28.0644 12.4216 31.2269L20.8202 39.4135L18.8376 50.9729C18.072 55.4363 22.7605 58.845 26.7712 56.7372L37.1522 51.2791L47.5336 56.7372C51.5489 58.8477 56.2309 55.4263 55.4667 50.9729L53.4841 39.4135L61.8829 31.2269C65.1257 28.0655 63.3362 22.552 58.8526 21.9004Z"
        fill={color2}
      />
      <path
        d="M20.4949 7.82326L17.6923 3.96598C16.9315 2.91914 15.466 2.68695 14.4192 3.44778C13.3722 4.20847 13.1401 5.67397 13.9008 6.72095L16.7034 10.5782C17.4647 11.6256 18.9303 11.857 19.9766 11.0964C21.0236 10.3359 21.2556 8.87024 20.4949 7.82326Z"
        fill={color}
      />
      <path
        d="M11.1992 42.3917C10.7992 41.161 9.47751 40.4868 8.2464 40.8872L3.61978 42.3906C2.38895 42.7903 1.71545 44.1125 2.11529 45.3434C2.51596 46.5766 3.84044 47.2474 5.06812 46.8479L9.69487 45.3445C10.9257 44.9448 11.5992 43.6227 11.1992 42.3917Z"
        fill={color}
      />
      <path
        d="M59.8771 3.44782C58.8302 2.68699 57.3647 2.91904 56.6039 3.96602L53.8013 7.8233C53.0405 8.87028 53.2727 10.3359 54.3196 11.0965C55.367 11.8574 56.8322 11.6248 57.5928 10.5783L60.3954 6.72098C61.1562 5.67401 60.924 4.20851 59.8771 3.44782Z"
        fill={color}
      />
      <path
        d="M70.6793 42.3907L66.0526 40.8873C64.8217 40.4867 63.4997 41.161 63.0997 42.3918C62.6998 43.6227 63.3733 44.9447 64.6042 45.3446L69.231 46.848C70.4593 47.2476 71.7833 46.576 72.1838 45.3436C72.5836 44.1127 71.9101 42.7907 70.6793 42.3907Z"
        fill={color}
      />
      <path
        d="M37.148 60.5576C35.8539 60.5576 34.8047 61.6068 34.8047 62.9009V67.657C34.8047 68.9512 35.8539 70.0003 37.148 70.0003C38.4421 70.0003 39.4913 68.9512 39.4913 67.657V62.9009C39.4914 61.6068 38.4423 60.5576 37.148 60.5576Z"
        fill={color}
      />
    </svg>
  );
}
