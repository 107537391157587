import { useParams } from "react-router";
import { GetBookingTags, GetPaymentStatusTag } from "../common/tags";
import { useBookingCancel, useGetBooking, useGetBookingWorkshopTimings, useRescheduleBooking, useUpdateCustomerFeedback } from "./booking-api";
import { dateFormat, timeFormat } from "../../config/common-function/date-time-format";
import IconDownload from "../../assets/icons/icon-common/icon-download";
import IconEdit from "../../assets/icons/icon-common/icon-edit";
import IconWarnPending from "../../assets/icons/icon-common/icon-warn-pending";
import IconCancelFile from "../../assets/icons/icon-common/icon-cancel-file";
import IconCheck from "../../assets/icons/icon-common/icon-check";
import { useContext, useRef, useState } from "react";
import dayjs from "dayjs";
import { Button, Dialog, FormControlLabel, InputAdornment, Radio, RadioGroup, Rating, TextField, Divider } from "@mui/material";
import { promptActions, promptContext } from "../../context/prompt";
import { LazyImage } from "../../assets/image/lazyImage";
import IconSuccess from "../../assets/icons/icon-common/icon-success";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useGetPdfExport } from "../../config/common-function/export";
import { Controller, useForm } from "react-hook-form";
import { useLanguage } from "../../language/useLanguage";


export const ISSUES_DATA = [
  { id: "engine", label: "Engine" },
  { id: "gearbox", label: "Gear Box" },
  { id: "clutch", label: "Clutch" },
  { id: "other", label: "Other" },
]

export default function BookingView() {
  const { id } = useParams();
  // const paymentFormRef = useRef();
  const booking = useGetBooking(id);
  const cancelBooking = useBookingCancel(id);
  const [popup, setPopup] = useState(false);
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const payment_status = searchParams.get("order_status");
  const [paymentPopup, setPaymentPopup] = useState(!!payment_status);
  const [paymentSelect, setPaymentSelect] = useState(false);
  const handleClosePopup = () => setPopup(false);
  const langData = useLanguage();
  const customer_order_id = booking.data?.booking_data.CustomerOrderItem.CustomerOrder.id ?? id
  const exportpdf = useGetPdfExport(`booking/invoice/download/${customer_order_id}`, `invoice-TSCOI${customer_order_id}`);

  if (booking.isLoading) return (<p>Loading...</p>)


  const handleCancelBooking = () => {
    cancelBooking.mutate();
  }
  const handleClosePaymentPopup = () => {
    setPaymentPopup(false);
    navigate(pathname, { replace: true });
  }

  const { booking_data, customer_address_details, service_details, workshop_details, payment_details, payment_options } = booking.data;
  const beforeServiceImageData = booking_data.BookingImages?.filter(img => img.image_tag === "before_service") ?? [];
  const afterServiceImageData = booking_data.BookingImages?.filter(img => img.image_tag === "after_service") ?? [];
  // console.debug({ beforeServiceImageData, afterServiceImageData })

  const isActionLoading = cancelBooking.isLoading;
  const issueString = Object.entries(JSON.parse(booking_data.issues)).map(([issue, text]) => {
    const label = ISSUES_DATA.find(obj => obj.id === issue)?.label;
    return issue === "other" ? `${label}: ${text}` : label
  }).join(", ");
  const timeRemaining = dayjs(`${booking_data.booking_date} ${booking_data.start_time}`, "YYYY-MM-DD HH:mm:ss").diff(dayjs(), "hour", true);


  return (
    <div className="booking-view">
      <div className="title-container">
        <h1>Booking of TSCO{booking_data.CustomerOrderItem.CustomerOrder.id}</h1>
        <GetBookingTags status={booking_data.CustomerOrderItem.status} />
      </div>
      <div className="status-action-container">
        <div className={`status ${booking_data.CustomerOrderItem.status}`}>
          {booking_data.CustomerOrderItem.status === "pending" ? (
            <>
              <div><IconWarnPending /></div>
              <p className="pending">{langData("Booking Pending")} - {workshop_details.workshop_name} {langData("will confirm request in maximum 24 hrs")}</p>
            </>
          ) : booking_data.CustomerOrderItem.status === "cancelled" ? (
            <>
              <div><IconCancelFile /></div>
              <p>{langData("Booking Cancelled")}</p>
            </>
          ) : booking_data.CustomerOrderItem.status === "completed" ? (
            <>
              <div><IconCheck color="#20A867" /></div>
              <p>{langData("Booking Completed")}</p>
            </>
          ) : booking_data.CustomerOrderItem.status === "payment-pending" ? (
            <>
              <div><IconWarnPending /></div>
              <p className="pending">{langData("Booking Payment Pending")}</p>
            </>
          ) : booking_data.CustomerOrderItem.status === "payment-failed" ? (
            <>
              <div><IconCancelFile /></div>
              <p>{langData("Booking Payment Failed")}</p>
            </>
          ) : (
            <>
              <div><IconCheck color="#20A867" /></div>
              <p>{langData("Booking Accepted")} - {workshop_details.workshop_name} {langData("has accepted your booking.")}</p>
            </>
          )}
        </div>
        {/* <div className="action-btn">
          <>
            {["pending", "upcoming"].includes(booking_data.CustomerOrderItem.status) && (
              <Button
                variant="contained"
                startIcon={<IconEdit color2="currentColor" color="currentColor" />}
                onClick={() => setPopup(true)}
                disabled={isActionLoading}
              >
                {langData("Reschedule")}
              </Button>
            )}
            {["payment-failed", "payment-pending"].includes(booking_data.CustomerOrderItem.status) && (
              payment_options.length > 1 ? (
                <>
                  <GatewaySelectionPopUp open={paymentSelect} onClose={() => setPaymentSelect(false)} paymentOptions={payment_options} />
                  <Button variant="contained" disabled={isActionLoading} onClick={() => setPaymentSelect(true)}>
                    {langData("Pay Now")}
                  </Button>
                </>
              ) : (
                payment_options[0].id === 2 ? (
                  <form
                    method={payment_options[0].payment_form_data.form.method}
                    action={payment_options[0].payment_form_data.form.action}
                  >
                    {payment_options[0].payment_form_data.inputs.map((inputData, index) => <input key={index} {...inputData} />)}
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isActionLoading}
                    >
                      {langData("Pay Now")}
                    </Button>
                  </form>
                ) : (
                  <form
                    id={payment_options[0].payment_form_data.form.id}
                    method={payment_options[0].payment_form_data.form.method}
                    name={payment_options[0].payment_form_data.form.name}
                    action={payment_options[0].payment_form_data.form.action}
                  >
                    {payment_options[0].payment_form_data.inputs.map((inputData, index) => <input key={index} {...inputData} />)}
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isActionLoading}
                    >
                      {langData("Pay Now")}
                    </Button>
                  </form>
                )
              )
            )}
            {["pending", "upcoming", "re-schedule"].includes(booking_data.CustomerOrderItem.status) && timeRemaining > 3 && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancelBooking}
                disabled={isActionLoading}
              >
                {langData("Cancel Booking")}
              </Button>
            )}
            {["payment-failed", "payment-pending"].includes(booking_data.CustomerOrderItem.status) && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancelBooking}
                disabled={isActionLoading}
              >
                {langData("Cancel Booking")}
              </Button>
            )}
          </>
        </div> */}
      </div>
      <div className="booking-details-container">
        <div className="booking-details">
          <h2>{langData("Booking Details")}</h2>
          <div className="grid">
            <p>{langData("Date & Time")}</p>
            <div>{`${dateFormat(booking_data.booking_date)} | ${timeFormat(booking_data.start_time)} - ${timeFormat(booking_data.end_time)}`}</div>
            <p>{langData("Service")}</p>
            <div><b>{`${service_details.Item.ServiceType.service_type_name} - ${booking_data.CustomerOrderItem.CustomerTractor.TractorModel.TractorBrand.brand_name} ${booking_data.CustomerOrderItem.CustomerTractor.TractorModel.model_name}`}</b></div>
            <p>{langData("Service Preference")}</p>
            <div style={{ textTransform: "capitalize" }}><b>{booking_data.service_location} Service</b></div>
            <p>{langData("Customer Address")}</p>
            <div>
              <b>{customer_address_details.address_name}</b>
              <div>{`${customer_address_details.address_1}, ${customer_address_details.address_2 ? customer_address_details.address_2 + ', ' : ""} ${customer_address_details.SubDistrict.subdistrict_name}, ${customer_address_details.SubDistrict.District.district_name}, ${customer_address_details.SubDistrict.District.State.state_name} - ${customer_address_details.pincode}`}</div>
            </div>
            <p>{langData("Workshop Address")}</p>
            <div>
              <b>{workshop_details.workshop_name}</b>
              <div>{`${workshop_details.address_1}, ${workshop_details.address_2 ? workshop_details.address_2 + ", " : ""} ${workshop_details.SubDistrict.subdistrict_name}, ${workshop_details.SubDistrict.District.district_name}, ${workshop_details.SubDistrict.District.State.state_name} - ${workshop_details.pincode}`}</div>
            </div>
            <p>{langData("Notes")}</p>
            <div>
              <b>{booking_data.notes ?? '-'}</b>
            </div>
            <p>{langData("Another Issue With Tractor")}</p>
            <div>{issueString}</div>
            <p>{langData("Payment Mode")}</p>
            <div>
              {payment_details ? (
                <>
                  <b>{payment_details?.type}</b>
                  <div>{payment_details?.transaction_id}</div>
                </>
              ) : (
                <div>CC Avenue</div>
              )}
            </div>
            {/* <div>Bank Transfer <span><TagCompleted text="Paid" /></span></div> */}
          </div>
        </div>
        <div className="price-details">
          <h2>{langData("Pricing")} <span style={{ fontSize: 16, marginLeft: 4 }}><GetPaymentStatusTag status={booking_data.CustomerOrderItem.CustomerOrder.payment_status} /></span></h2>
          <div className="grid">
            <p>{langData("Basic Service Charges")}</p>
            <div><b>₹ {booking_data.CustomerOrderItem.amount}</b></div>
            {booking_data.home_service_amount && booking_data.home_service_amount !== "0" && <>
              <p>{langData("Home Service Charges")}</p>
              <div><b>₹ {booking_data.home_service_amount}</b></div>
            </>}
            <p>{langData("Tax")} ({Number(service_details.TaxGroup.cgst) + Number(service_details.TaxGroup.sgst)}%)</p>
            <div><b>₹ {booking_data.CustomerOrderItem.tax_amount ?? 0}</b></div>
            <hr />
            <p>{langData("Total")}</p>
            {/* <div style={{ fontSize: 24 }}><b>₹ {formatAmount(formatAmount(booking_data.service_amount) + formatAmount(booking_data.home_service_amount) + formatAmount(booking_data.service_tax_amount ?? 0))}</b></div> */}
            <div style={{ fontSize: 18 }}><b>₹ {service_details.offer_total}</b></div>
            {/* {pricing_details.coupon && pricing_details.coupon !== "0" && <> */}
            {/* <p>{langData("Coupon")}</p>
            <div style={{ fontSize: 18 }}><b>₹ {pricing_details.coupon}</b></div>
            {/* </>} */}
            <p>{langData("Grand Total")}</p>
            {/* <div style={{ fontSize: 24 }}><b>₹ {pricing_details.grand_total}</b></div> */}
          </div>
        </div>
        {booking_data.CustomerOrderItem.CustomerOrder.payment_status === "completed" && (
          <div className="download-details">
            <div onClick={() => exportpdf()} style={{ cursor: "pointer" }}>
              {langData("Download Invoice")} {" "}
              <span>
                <IconDownload />
              </span>
            </div>
          </div>
        )}
        {!(beforeServiceImageData.length === 0 && afterServiceImageData.length === 0) && (
          <div className="photo-details">
            <h2>Tractor Photos</h2>
            <p style={{marginTop: 8}}>{langData("Before tractor service image")}</p>
            <div>
              {beforeServiceImageData.map(img => (
                <LazyImage key={img.id} name={img.image_name} width={100} height={100} />
              ))}
            </div>
            {/* <hr style={{border: '0.1vw solid var(--clr-primary)'}}/> */}
            <Divider style={{borderColor: 'var(--clr-primary)'}}/>
            <p style={{marginTop: 8}}>{langData("After tractor service image")}</p>
            <div>
              {afterServiceImageData.map(img => (
                <LazyImage key={img.id} name={img.image_name} width={100} height={100} />
              ))}
            </div>
          </div>
        )}
        { booking_data.CustomerOrderItem.status === "completed" && !booking.isFetching && <CustomerFeedback
          booking_id={id}
          ratings={booking.data.feedback?.ratings ? Number(booking.data.feedback.ratings) : 0}
          comments={booking.data.feedback?.comments ?? ""}
        />}
      </div>
      {popup && <RescheduleDialog
        open={popup}
        booking_id={id}
        workshop_id={booking_data.workshop_id}
        onClose={handleClosePopup}
        startingDate={booking_data.booking_date}
        timeSlot={`${dayjs(booking_data.start_time, 'HH:mm:ss', 'HH:mm').format('hh:mm A')} - ${dayjs(booking_data.end_time, 'HH:mm:ss', 'HH:mm').format('hh:mm A')}`}
      />}
      <PaymentStatusDialog
        open={paymentPopup}
        payment_status={payment_status}
        handleClosePaymentPopup={handleClosePaymentPopup}
      />
    </div>
  )
}

function CustomerFeedback({
  booking_id,
  ratings = 0,
  comments = "",
}) {

  const updateFeedback = useUpdateCustomerFeedback(booking_id)
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      ratings,
      comments,
    }
  })
  const feedbackLength = watch("comments").length;
  const submit = (inputs) => {
    console.debug(inputs);
    const form = new FormData();
    for (const key in inputs) { form.append(key, inputs[key]) }
    updateFeedback.mutate(form);
  }
  const langData = useLanguage();
  return (
    <div className="feedback-details">
      <form noValidate onSubmit={handleSubmit(submit)}>
        <h2 style={{ marginBottom: 16 }}>{langData("Give Us Feedback")}</h2>
        <div className="field-container">
          <label>{langData("Rate Us")}</label>
          <div>
            <Controller
              control={control}
              rules={{ required: langData("Please Give Ratings") }}
              name="ratings"
              render={({ field }) => (
                <Rating
                  {...field}
                  onChange={(__event, newValue) => field.onChange(newValue)}
                  sx={{
                    "& label": {
                      fontSize: 32,
                      mb: 0,
                    }
                  }}
                />
              )}
            />
          </div>
          <p className="form-error-text">{errors.ratings?.message}</p>
        </div>
        <div className="field-container">
          <label>{langData("Tell Us More About Service Experience")}</label>
          <TextField
            multiline
            rows={4}
            placeholder="Describe Your Experience Here"
            inputProps={{ maxLength: 1000 }}
            InputProps={{
              endAdornment:
                <InputAdornment
                  position="end"
                  sx={{
                    position: "absolute",
                    right: 16,
                    bottom: 16,
                    fontSize: 12
                  }}
                >
                  <p>{`${feedbackLength} / 1000`}</p>
                </InputAdornment>
            }}
            {...register("comments", {
              minLength: {
                value: 2,
                message: "Atleast two character require",
              },
              maxLength: {
                value: 1000,
                message: "Limit exceed, 1000 character allowed",
              },
            })}
          />
          <p className="form-error-text">{errors.comments?.message}</p>
        </div>
        <Button variant="contained" type="submit" disabled={updateFeedback.isLoading || !isDirty}>{langData("Submit")}</Button>
      </form>
    </div>
  )
}

function RescheduleDialog({
  open = false,
  booking_id,
  workshop_id,
  onClose,
  startingDate = null,
  timeSlot = null,
}) {
  const prompt = useContext(promptContext);
  const [inputs, setInputs] = useState({ booking_date: startingDate, booking_time: timeSlot });
  const updateDate = useRescheduleBooking(booking_id);
  const { data: wsDateTime, isLoading } = useGetBookingWorkshopTimings(workshop_id);
  const langData = useLanguage();
  if (isLoading) return (<p>Loading...</p>)
  const timeSlots = wsDateTime.find(day => day.date === inputs.booking_date).time_slots;

  function handleSubmit() {
    console.debug('SUBMITTING: ', inputs);
    if (startingDate === inputs.booking_date && timeSlot === inputs.booking_time) {
      console.debug('CHANGES DATE OR TIME: ');
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: "Please Change Value to Update" } })
      return;
    }
    const timeArr = inputs.booking_time.split("-");
    const data = {
      booking_date: inputs.booking_date,
      start_time: dayjs(timeArr[0], "hh:mm A").format("HH:mm"),
      end_time: dayjs(timeArr[1], "hh:mm A").format("HH:mm")
    }
    const form = new FormData();
    for (const key in data) { form.append(key, data[key]) }
    updateDate.mutate(form, {
      onSuccess: () => {
        onClose();
      }
    });
  }

  return (
    <Dialog open={open} onClose={onClose} className='popup re-schedule-dialog'>
      <div className='content-container'>
        <h3>Select Date</h3>
        <div className="reschedule_dates" style={{ display: "flex", gap: "1em" }}>
          {wsDateTime.map(day => (
            <Button
              key={day.date}
              className={day.date === inputs.booking_date ? "Active" : ""}
              variant="outlined"
              color="primary"
              style={{ height: 75 }}
              disabled={!day.status}
              onClick={() => setInputs((prevInput) => ({ ...prevInput, booking_date: day.date }))}
            >
              {day.day}<br />{day.date.split("-")[2]}
            </Button>
          ))}
        </div>
        <h3>{langData("Select Time")}</h3>
        <div className="reschedule_dates" style={{ display: "flex", gap: "1em", flexWrap: "wrap", justifyContent: "center" }}>
          {timeSlots.map(time => (
            <Button
              key={time}
              className={time === inputs.booking_time ? "Active" : ""}
              variant="outlined"
              color="primary"
              style={{ flexBasis: 160, paddingInline: 4 }}
              onClick={() => setInputs((prevInputs) => ({ ...prevInputs, booking_time: time }))}
            >
              {time}
            </Button>
          ))}
        </div>
        <Button
          autoFocus
          variant='contained'
          sx={{ minWidth: 100 }}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </div>
    </Dialog>
  )
}

function PaymentStatusDialog({
  open = false,
  payment_status,
  handleClosePaymentPopup
}) {

  return (
    <Dialog open={open} onClose={handleClosePaymentPopup} className='popup payment-status'>
      <div className="payment-status">
        <div>
          {payment_status === 'Success' ? (
            <IconSuccess height={136} width={136} />
          ) : (
            <IconCancelFile height={136} width={136} />
          )}
        </div>
        <div>
          {payment_status === 'Success' ? (
            <>
              <h1>Payment successful</h1>
              <Button variant="contained" onClick={handleClosePaymentPopup} style={{ marginTop: '1rem', minWidth: 100 }}> Okay</Button>
            </>
          ) : (
            <>
              <h1>Payment Cancelled</h1>
              <Button variant="contained" onClick={handleClosePaymentPopup} style={{ marginTop: '1rem', minWidth: 100 }} > Okay</Button>
            </>
          )}
        </div>
      </div>
    </Dialog>
  )
}

function GatewaySelectionPopUp({
  open = false,
  onClose,
  paymentOptions,
}) {
  const langData = useLanguage();
  const [paymentGatewayId, setPaymentGatewayId] = useState(() => paymentOptions?.find(p => p.is_default)?.id ?? 1);
  const paymentFormRef = useRef();
  const handlePay = () => {
    const paymentData = paymentOptions.find(p => p.id === paymentGatewayId).payment_form_data;
    for (const key in paymentData.form) {
      paymentFormRef.current.setAttribute(key, paymentData.form[key]);
    }
    paymentData.inputs.forEach((inputAttr) => {
      const input = document.createElement('input');
      for (const key in inputAttr) {
        input.setAttribute(key, inputAttr[key]);
      }
      paymentFormRef.current.appendChild(input);
    })
    paymentFormRef.current.submit();
  }
  return (
    <Dialog open={open} onClose={onClose} className='popup payment-status'>
      <div style={{ textAlign: "start" }}>
        <h2>{langData("Payment Gateway")}</h2>
        <RadioGroup row value={paymentGatewayId.toString()} onChange={(__event, newValue) => { setPaymentGatewayId(Number(newValue)) }} sx={{ mt: 1 }} >
          {paymentOptions.map((pay) =>
            <FormControlLabel key={pay.id} value={pay.id.toString()} control={<Radio color="secondary" />} label={pay.payment_gateway_name} />
          )}
        </RadioGroup>
        <form ref={paymentFormRef} style={{ display: 'none' }}></form>
        <div style={{ display: "flex", gap: '1rem', alignItems: "center", justifyContent: "flex-end" }}>
          <Button variant="contained" onClick={handlePay}>Pay Now</Button>
          <Button variant="outlined" onClick={onClose}>Cancel</Button>
        </div>
      </div>
    </Dialog>
  )
}