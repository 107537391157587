import { useContext } from "react";
import useAxios from "../../config/axios/useAxios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { promptActions, promptContext } from "../../context/prompt";
import { useAuthContext } from "../../context/authContext";

export function useGetProductWarranty() {
  const protectedAxios = useAxios();
  // const prompt = useContext(promptContext);
  const API = "extended_warranty/get-product-warranty";
  return useMutation({
    mutationFn: (form) => protectedAxios.post(API, form),
    // onSuccess: (response) => {
    //   prompt({ type: promptActions.SHOW_PROMPT, payload: { type: "success", message: response.data?.message || "Added successfully" } });
    // },
    // onError: (error) => {
    //   prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Error while adding" } })
    // }
  })
}

export function useGetWorkshopList() {
  const protectedAxios = useAxios();
  const API = "getdata/workshops";
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);

  return useQuery({
    queryKey: [API],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(API);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken("clearToken");
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Token Expired" } });
        }
        return;
      }
    },
  })
}

export function useAddExtendWarrantyRequest() {
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const API = "extended_warranty/add";

  return useMutation({
    mutationFn: (form) => protectedAxios.post(API, form),
    // onSuccess: (response) => {
    //     prompt({ type: promptActions.SHOW_PROMPT, payload: { type: "success", message: response.data?.message || "Added successfully" } });
    // },
    onError: (error) => {
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Error while adding" } })
    }
  })
}


export function useIsWarrantyAvailable() {
  const protectedAxios = useAxios();
  return async (value) => {
    try {
      const response = await protectedAxios.get(`extended_warranty/check-model/${value}`);
      return response.data.isAvailable || response.data.message;
    } catch (error) {
      console.debug(error);
      return "Server Error";
    }
  }
}

export function useGetModelAll(brand_id) {
  const API = "getdata/models"
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);

  return useQuery({
    queryKey: [API, "all", brand_id],
    enabled: !!brand_id,
    cacheTime: 1000 * 60 * 5,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${brand_id}`);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken("clearToken");
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || "Token Expired" } });
        }
        return;
      }
    }
  })
}