import {
  Autocomplete,
  Avatar,
  Button,
  Dialog,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import IconTractorDualColor from "../../../assets/icons/icon-common/icon-tractor-dual-color";
import IconStar from "../../../assets/icons/icon-common/icon-star";
import IconTimeout from "../../../assets/icons/icon-common/icon-timeout";
import IconCircleCheck from "../../../assets/icons/icon-common/icon-circle-check";
import { Fragment, useEffect, useRef, useState } from "react";
import { LazyImage } from "../../../assets/image/lazyImage";
import {
  useAddCustomerTractor,
  useGetAllServiceTypeV2,
  useGetBrand,
  useGetCustomerTractorList,
  useGetModel,
  useGetCustomerTractor,
} from "../homepage-api";
import { useNavigate, useLocation } from "react-router-dom";
import InputNumber from "../../../assets/custom-fields/input-number";
import { useAuthContext } from "../../../context/authContext";
import { getLastYears } from "../../../config/common-function/data-generate";
import { AddTractorModel as ProfileAddTractorModel } from "../../profile/components/my-tractor";
import { AutocompleteImageOption } from "../../../assets/autocomplete/autocomplete-option";
import { useLanguage } from "../../../language/useLanguage";

const getYears = getLastYears(30);

const defaultValues = {
  tractor_brand_id: null,
  tractor_model_id: null,
};

const SERVICE_TYPE_IMAGE_PATH = [
  "icons/service-basic.svg",
  "icons/service-standard.svg",
  "icons/service-premium.svg",
];

const SERVICE_TASK_LIST = [
  [
    { id: "BASIC-1", task_order: 1, Task: { task_details: "15W40 CH4 Engine Oil Change" } },
    { id: "BASIC-2", task_order: 2, Task: { task_details: "Bosch Oil Filter Change" } },
    { id: "BASIC-3", task_order: 3, Task: { task_details: "Bosch Diesel Filter Change" } },
    { id: "BASIC-4", task_order: 4, Task: { task_details: "Greasing" } },
    { id: "BASIC-5", task_order: 5, Task: { task_details: "Key Chain" } },
    { id: "BASIC-6", task_order: 6, Task: { task_details: "Sticker" } },
    { id: "BASIC-7", task_order: 7, Task: { task_details: "Air Pressure Check" } },
  ],
  [
    { id: "STANDARD-1", task_order: 1, Task: { task_details: "15W40 CH4 Engine Oil Change" } },
    { id: "STANDARD-2", task_order: 2, Task: { task_details: "Bosch Oil Filter Change" } },
    { id: "STANDARD-3", task_order: 3, Task: { task_details: "Bosch Diesel Filter Change" } },
    { id: "STANDARD-4", task_order: 4, Task: { task_details: "Bosch Air Filter Change" } },
    { id: "STANDARD-5", task_order: 5, Task: { task_details: "Top Wash" } },
    { id: "STANDARD-6", task_order: 6, Task: { task_details: "Greasing" } },
    { id: "STANDARD-7", task_order: 7, Task: { task_details: "Key Chain" } },
    { id: "STANDARD-8", task_order: 8, Task: { task_details: "Sticker" } },
    { id: "STANDARD-9", task_order: 9, Task: { task_details: "Air Pressure Check" } },
  ],
  [
    { id: "PREMIUM-1", task_order: 1, Task: { task_details: "Premium 15W40 CI4 Plus Engine Oil Change" } },
    { id: "PREMIUM-2", task_order: 2, Task: { task_details: "Bosch Oil Filter Change" } },
    { id: "PREMIUM-3", task_order: 3, Task: { task_details: "Bosch Diesel Filter Change" } },
    { id: "PREMIUM-4", task_order: 4, Task: { task_details: "Bosch Air Filter Change" } },
    { id: "PREMIUM-5", task_order: 5, Task: { task_details: "Top Wash" } },
    { id: "PREMIUM-6", task_order: 6, Task: { task_details: "Greasing" } },
    { id: "PREMIUM-7", task_order: 7, Task: { task_details: "Key Chain" } },
    { id: "PREMIUM-8", task_order: 8, Task: { task_details: "Sticker" } },
    { id: "PREMIUM-9", task_order: 9, Task: { task_details: "Tyre Polish" } },
    { id: "PREMIUM-10", task_order: 10, Task: { task_details: "Hydraulic Oil Check" } },
    { id: "PREMIUM-11", task_order: 11, Task: { task_details: "Air Pressure Check" } },
  ],
  [
    { id: "TSServiceKit-1", task_order: 1, Task: { task_details: "15W40 Engine oil" } },
    { id: "TSServiceKit-2", task_order: 2, Task: { task_details: "Bosch Engine oil Filter" } },
    { id: "TSServiceKit-3", task_order: 3, Task: { task_details: "Bosch Disel Filter" } },
    { id: "TSServiceKit-4", task_order: 4, Task: { task_details: "Bosch Air Filter" } },
  ],
];

export default function ServiceSelection({ data }) {
  const navigate = useNavigate();
  const { getData } = useAuthContext();
  const { userData } = getData();
  const location = useLocation();
  const langData = useLanguage();


  const customer_id = userData?.user_id;
  defaultValues.tractor_brand_id = location.state?.tractor_brand_id ?? null;
  defaultValues.tractor_model_id = location.state?.model_id ?? null;
  useEffect(() => {
    window.history.replaceState({}, location.state);
  }, []);
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
    setFocus,
  } = useForm({ defaultValues });
  const [tractor_brand_id, tractor_model_id] = watch([
    "tractor_brand_id",
    "tractor_model_id",
  ]);
  const submitBtnRef = useRef();
  const [addTrPopup, setAddTrPopup] = useState(false);
  const [openPopup, setOpenPopup] = useState({
    open: false,
    customerMultiTractor: [],
  });
  const brandList = useGetBrand();
  const modelList = useGetModel(tractor_brand_id);
  const serviceTypeListV2 = useGetAllServiceTypeV2(tractor_model_id);
  const customerTractorList = useGetCustomerTractorList(customer_id);
  const addTractor = useAddCustomerTractor(customer_id);
  const [serviceId, setServiceId] = useState(null);
  // const customerAllTractor = customerTractorList?.data;

  const customerTractor = useGetCustomerTractor(tractor_brand_id);
  const all_model = customerTractor.data?.all_tractors ?? [];
  const your_model = customerTractor.data?.your_tractors ?? [];

  const customGroupTractorList = [
    ...your_model.map((a) => {
      a.group = "My Tractors";
      return a;
    }),
    ...all_model.map((a) => {
      a.group = "All Tractors";
      return a;
    }),
  ];

  const [cart, setCart] = useState(null);
  const AddService = (service_type) => {
    const serviceID = service_type.Items[0].Service.id;
    setServiceId(serviceID);
    const cartData = {
      service_id: serviceID,
      brand_name: service_type.Items[0].ItemModels[0].TractorModel.TractorBrand.brand_name,
      model_name: service_type.Items[0].ItemModels[0].TractorModel.model_name,
      model_image: service_type.Items[0].ItemModels[0].TractorModel.model_img,
      service_type_name: service_type.service_type_name,
      offer_total: service_type.Items[0].Service.offer_total,
    };
    setCart(cartData);
    // console.debug("SERVICE_ID: ", cartData);
  };

  const onProceed = (selectedCustTractor) => {
    // console.debug("SELECTED TRACTOR: ", selectedCustTractor);
    let custTractor;
    if (!selectedCustTractor) {
      custTractor = customerTractorList.data.filter(
        (tr) => tr.tractor_model_id === tractor_model_id && tr.status === 1
      );
      if (custTractor.length === 0) setAddTrPopup(true);
      else if (custTractor.length >= 1) setOpenPopup({ open: true, customerMultiTractor: custTractor });
      return;
    } else {
      setOpenPopup({ open: false, customerMultiTractor: [] });
    }

    const state = {
      service_id: serviceId,
      service: serviceTypeListV2.data.find(
        (ST) => ST.Items[0].Service.id === serviceId
      ),
      tractor_model: modelList.data.find(
        (model) => model.id === selectedCustTractor.tractor_model_id
      ),
      tractor_model_id: selectedCustTractor.tractor_model_id,
      customer_tractor_id: selectedCustTractor.id,
      tractor_brand_id: selectedCustTractor.TractorModel.TractorBrand.id,
    };
    // console.debug('state: ', state)
    navigate("/booking/add", { state });
  };

  const handleClosePopup = () => setOpenPopup({ open: false });

  const onReset = () => {
    setCart(null);
    setServiceId(null);
    reset();
  };

  function handleSelectTractor() {
    if (!tractor_brand_id) {
      setFocus("tractor_brand_id");
      return;
    } else if (!tractor_model_id) {
      setFocus("tractor_model_id");
    }
  }
  const closeTrPopup = () => setAddTrPopup(false);
  const handleTractorAdd = (inputs) => {
    inputs.customer_id = customer_id;
    inputs.tractor_model_id = tractor_model_id;
    console.debug("SUBMITTING: ", inputs);
    const form = new FormData();
    for (const key in inputs) {
      form.append(key, inputs[key]);
    }
    addTractor.mutate(form, {
      onSuccess: (response) => {
        closeTrPopup();
        const state = {
          service: serviceTypeListV2.data.find(
            (ST) => ST.Items[0].Services[0].id === serviceId
          ),
          tractor_model: modelList.data.find(
            (model) => model.id === tractor_model_id
          ),
          customer_tractor_id: response.data.id,
        };
        navigate("/booking/add", { state });
      },
    });
  };

  const handelScroll = () => {
    if (tractor_brand_id && tractor_model_id) {
      const cardelements = document.getElementsByClassName("service-card")[0]
      cardelements.scrollIntoView({
        inline: 'start',
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  return (
    <div
      className="service-selection-container"
      id="select-service"
      style={{ scrollMarginTop: 190 }}
    >
      <div className="tractor-model-list-container">
        <div className="field-container">
          <label>{langData("Select Brand")}</label>
          <Controller
            name="tractor_brand_id"
            control={control}
            rules={{ required: "Select Tractor Brand" }}
            render={({ field: { ref, ...field } }) => {
              return (
                <Autocomplete
                  {...field}
                  options={brandList.data ?? []}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={ref}
                      placeholder={langData("Select Any Brand")}
                      error={!!errors.tractor_brand_id}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: <IconStar style={{ marginLeft: 4 }} />,
                      }}
                    />
                  )}
                  getOptionLabel={(option) =>
                    option.label ??
                    brandList.data?.find((opt) => opt.id === option)?.label ??
                    ""
                  }
                  onChange={(__event, data) => {
                    field.onChange(data?.id ?? null);
                    if (data) setFocus("tractor_model_id");
                    setCart(null);
                    setServiceId(null);
                    setValue("tractor_model_id", null);
                  }}
                  renderOption={(params, option) => (
                    <AutocompleteImageOption
                      {...params}
                      option={option}
                      imgSrc={option.brand_img}
                      fallBackSrc={"placeholder-tractor-brand.svg"}
                    />
                  )}
                  getOptionDisabled={(option) => option.status === 0}
                />
              );
            }}
          />
          <p className="form-error-text">{errors.tractor_brand_id?.message}</p>
        </div>
        <div className="field-container">
          <label>{langData("Select Model")}</label>
          <Controller
            name="tractor_model_id"
            control={control}
            rules={{ required: "Select Tractor Model" }}
            render={({ field: { ref, ...field } }) => {
              return (
                <Autocomplete
                  {...field}
                  options={customGroupTractorList}
                  onChange={(__event, data) => {
                    field.onChange(data?.id ?? null);
                    if (data) {
                      setValue("tractor_brand_id", data.tractor_brand_id);
                      submitBtnRef.current.focus();
                    }
                    setCart(null);
                    setServiceId(null);
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={ref}
                      placeholder={langData("Select Any Model")}
                      error={!!errors.tractor_model_id}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <IconTractorDualColor style={{ marginLeft: 4 }} />
                        ),
                      }}
                    />
                  )}
                  groupBy={(option) => option.group}
                  getOptionLabel={(option) => {
                    if (option.label) {
                      return option.label;
                    } else {
                      return (
                        customGroupTractorList?.find((opt) => opt.id === option)
                          ?.label ?? "Loading..."
                      );
                    }
                  }}
                  getOptionDisabled={(option) => option.status === 0}
                  renderOption={(params, option) => (
                    <AutocompleteImageOption
                      {...params}
                      option={option}
                      imgSrc={option.model_img}
                      fallBackSrc={"placeholder-tractor-model.svg"}
                    />
                  )}
                />
              );
            }}
          />
          <p className="form-error-text">{errors.tractor_model_id?.message}</p>
          {openPopup.open && (
            <SelectTractor
              open={openPopup.open}
              tractor_list={openPopup.customerMultiTractor}
              onProceed={onProceed}
              onClose={handleClosePopup}
            />
          )}
        </div>
        <Button
          style={{ alignSelf: "flex-end", marginBottom: 20 }}
          variant="contained"
          ref={submitBtnRef}
          onClick={handelScroll}
        >
          {langData("Search")}
        </Button>
      </div>
      <div className="service-container">
        <h5>{data?.heading}</h5>
        <h2>{langData(data?.sub_heading)}</h2>
        <div className="service-card-container">
          {serviceTypeListV2.isLoading ? (
            [1, 2, 3].map((no) => <LoadingServiceCard key={no} />)
          ) : serviceTypeListV2.data.length === 0 ? (
            <NoService />
          ) : (
            serviceTypeListV2.data.map((st, i) => (
              <ServiceCard
                key={st.id}
                imagePath={SERVICE_TYPE_IMAGE_PATH[i] ?? SERVICE_TYPE_IMAGE_PATH[0]}
                title={`
                ${st.service_type_name ?? "N/A"}
                ${tractor_model_id ? " - " + (st.Items?.[0]?.ItemModels?.[0]?.TractorModel.TractorBrand.brand_name ?? "") + " " + (st.Items?.[0]?.ItemModels?.[0].TractorModel.model_name ?? '') : ''}
                `}
                time={st.time_req_min}
                service={st.Items?.[0]?.Service ?? {}}
                serviceDesc={st.service_type_desc}
                serviceTasks={st.Items?.[0]?.Service?.ServiceTasks ?? SERVICE_TASK_LIST[i]}
                model_id={tractor_model_id}
                brand_id= {tractor_brand_id}
                service_id={serviceId}
                addService={() => AddService(st)}
                onSelectTractor={handleSelectTractor}
              />
            ))
          )}
        </div>
      </div>
      {cart && (
        <div className="cart">
          <div className="cart-details">
            <Avatar className="icon-container">
              <LazyImage
                name={cart.model_img}
                width={50}
                height={50}
                borderRadius={50}
                fallbackImage="tractor-model-image.svg"
              />
            </Avatar>
            {/* <div className="tractor-details">
              <p className="title">{langData("Selected Tractor")}</p>
              <p className="details">{cart.brand_name + ' ' + cart.model_name}</p>
            </div>
            <div style={{ width: 1, backgroundColor: "#FFFFFF4B" }}></div> */}
            <div className="service-details">
              <p className="title">{langData("Selected Service")}</p>
              <p className="details">
                {langData(cart.service_type_name)} - {langData(cart.brand_name)} {langData(cart.model_name)}
              </p>
              <p className="details">
                ₹ {cart.offer_total}
              </p>
            </div>
          </div>
          <div className="btn-container">
            <Button variant="outlined" color="white" onClick={onReset}>
              {langData("Reset")}
            </Button>
            <Button
              variant="contained"
              color="white"
              onClick={() => onProceed()}
            >
              {langData("Proceed")}
            </Button>
          </div>
        </div>
      )}
      {addTrPopup && (
        <AddTractorModel
          open={addTrPopup}
          onClose={closeTrPopup}
          submit={handleTractorAdd}
          brandName={
            brandList.data.find((br) => br.id === tractor_brand_id).label
          }
          modelName={
            modelList.data.find((mo) => mo.id === tractor_model_id).label
          }
        />
      )}
    </div>
  );
}

function ServiceCard({
  imagePath = "icons/service-basic.svg",
  title = "Basic Service",
  time = "N/A",
  service,
  serviceDesc,
  serviceTasks,
  model_id,
  brand_id,
  service_id,
  addService,
  onSelectTractor,
}) {
  // console.debug({ service });
  const navigate = useNavigate();
  const { getData } = useAuthContext();
  const { isAuth } = getData();
  const langData = useLanguage();
  const mrpPrice = service?.mrp_total;
  const offerPrice = service?.offer_total;

  return (
    <div className="service-card" style={{ scrollMarginTop: 96 }}>
      <div className="image-container">
        <img src={imagePath} alt={imagePath} />
      </div>
      <div className="card-title">{langData(title)}</div>
      <p className="card-subtitle">{langData(serviceDesc)}</p>
      <span className="tag">
        <div className="icon">
          <IconTimeout />
        </div>
        <div className="text">{time} {langData("Mins")}</div>
      </span>
      <TaskList
        tasks={serviceTasks?.sort((a, b) => a.task_order - b.task_order)}
      />
      <div className="card-btn">
        {isAuth ? (
          <>
            {!model_id && (
              <Button variant="contained" onClick={onSelectTractor}>
                {langData("+ Select Tractor")}
              </Button>
            )}
            {model_id && service_id !== service?.id && (
              <Button variant="outlined" onClick={addService}>
                {langData("+ Add")}
              </Button>
            )}
            {model_id && service_id === service?.id && (
              <Button variant="contained">{langData("Added")}</Button>
            )}
          </>
        ) : (
          <Button variant="contained" onClick={() => navigate("/login", {
            state: {
              redirectTo: "/",
              tractor_brand_id: brand_id, 
              model_id: model_id
            }
          })}>
            {langData("Login For Best Price")}
          </Button>
        )}
        {isAuth && (
          <div>
            {mrpPrice && <span className="crossed">{`₹ ${mrpPrice}`}</span>}
            {offerPrice && <span>{`₹ ${offerPrice}`}</span>}
          </div>
        )}
      </div>
    </div>
  );
}

function TaskList({ tasks = [] }) {
  const [open, setOpen] = useState(false);
  const toggleShow = () => setOpen((prev) => !prev);
  const langData = useLanguage();
  const allowLength = 5;
  const isExceed = tasks.length > allowLength;
  const taskData = isExceed && !open ? tasks.slice(0, allowLength) : tasks;
  return (
    <div className="task-list">
      {taskData.map((task) => (
        <Fragment key={task.id}>
          <div>
            <IconCircleCheck />
          </div>
          <p>{langData(task.Task.task_details)}</p>
        </Fragment>
      ))}
      {isExceed && (
        <div className="action-text" onClick={toggleShow}>
          {open ? "- Show Less" : "+ Show More"}
        </div>
      )}
    </div>
  );
}

const TractorInputs = {
  reg_no: "",
  purchase_year: null,
  manufacture_year: null,
  hours_used: "",
};

function AddTractorModel({
  open = false,
  onClose,
  submit,
  brandName = "Brand",
  modelName = "Model",
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: TractorInputs });
  const langData = useLanguage();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="popup add-tractor"
      style={{ maxWidth: "unset" }}
    >
      <div className="content-container">
        <div className="info">
          Seems like you have not added selected tractor
        </div>
        <div className="tractor-details">
          <img src={`icons/tractor-model-demo.svg`} style={{ height: 60 }} />
          <div>
            <p>{brandName}</p>
            <p>{modelName}</p>
          </div>
        </div>
        <form
          className="form-container"
          noValidate
          onSubmit={handleSubmit(submit)}
        >
          <div className="field-row">
            <div className="field-container">
              <label className="required">{langData("Chassis No.")}</label>
              <TextField
                error={!!errors?.reg_no}
                {...register("reg_no", {
                  required: langData("Field is Required"),
                })}
              />
              <p className="form-error-text">{errors.reg_no?.message}</p>
            </div>
            <div className="field-container">
              <label className="required">{langData("Year of Purchase")}</label>
              <Controller
                name="purchase_year"
                control={control}
                rules={{
                  required: langData("Please Select purchase year"),
                  validate: {
                    notLessThanManufacture: (value, formValues) => {
                      return Number(value) < Number(formValues.manufacture_year)
                        ? "Please select valid year"
                        : true;
                    },
                  },
                }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={getYears}
                      value={field.value?.toString() ?? null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={langData("Purchase year")}
                          error={!!errors.purchase_year}
                        />
                      )}
                      onChange={(___event, newValue) => {
                        field.onChange(newValue ? Number(newValue) : newValue);
                      }}
                    />
                  );
                }}
              />
              <p className="error-text">{errors.purchase_year?.message}</p>
            </div>
          </div>
          <div className="field-row">
            <div className="field-container">
              <label className="required">{langData("Year of Manufacture")}</label>
              <Controller
                name="manufacture_year"
                control={control}
                rules={{
                  required: langData("Please Select Year of Manufacture"),
                  deps: ["purchase_year"],
                }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={getYears}
                      value={field.value?.toString() ?? null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={langData("Year of Manufacture")}
                          error={!!errors.manufacture_year}
                        />
                      )}
                      onChange={(___event, newValue) => {
                        field.onChange(newValue ? Number(newValue) : newValue);
                      }}
                    />
                  );
                }}
              />
              <p className="form-error-text">
                {errors.manufacture_year?.message}
              </p>
            </div>
            <div className="field-container">
              <label className="required">{langData("Hours Used")}</label>
              <Controller
                name="hours_used"
                control={control}
                rules={{
                  required: langData("Field is Required"),
                }}
                render={({ field }) => (
                  <InputNumber {...field} error={!!errors.hours_used} />
                )}
              />
              <p className="form-error-text">{errors.hours_used?.message}</p>
            </div>
          </div>
          <div className="btn-container">
            <Button sx={{ minWidth: 120 }} type="submit" variant="contained">
              {langData("Save & Next")}
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
}

function SelectTractor({ open = false, tractor_list, onProceed, onClose }) {
  const [addVehiclePopup, setAddVehiclePopup] = useState(false);
  const handlePopUpClose = () => setAddVehiclePopup(false);
  const langData = useLanguage();
  // console.debug('tractor_list', tractor_list);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="popup"
      sx={{ "& .MuiDialog-paper": { width: "75vw", maxWidth: 700 } }}
    >
      <div className="choose-tractor">
        <h1 style={{ paddingBottom: 28 }}>{langData("Select Your Vehicle")}</h1>
      </div>
      <div className="select-card-container">
        {tractor_list?.map((a) => (
          <div
            className="select-service-tractor-card"
            key={a.id}
          >
            <RadioGroup
              onClick={() => onProceed(a)}
            >
              <div style={{ display: 'flex', gap: 5 }}>
                <FormControlLabel
                  value={a.id}
                  control={<Radio />}
                  style={{ alignSelf: "self-start", margin: 0 }}
                />
                <div style={{ padding: 9 }}>
                  <h3>
                    {a.TractorModel.TractorBrand.brand_name}{" "}
                    {a.TractorModel.model_name}
                  </h3>
                  <div className="tractor-des-details">
                    <div className="tractor-chassis-details">
                      <p className="detail-title">{langData("Chassis No.")}</p>
                      <p className="detail-des">{a.reg_no}</p>
                    </div>
                    <div className="tractor-yop-details">
                      <p className="detail-title">{langData("Year of Purchase")}</p>
                      <p className="detail-des">{a.purchase_year}</p>
                    </div>
                    <div className="tractor-uh-details">
                      <p className="detail-title">{langData("Used Hours")}</p>
                      <p className="detail-des">{a.hours_used}</p>
                    </div>
                  </div>
                </div>
              </div>
            </RadioGroup>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          gap: 16,
          marginTop: 16,
          justifyContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <Button variant="contained" onClick={() => setAddVehiclePopup(true)}>
          {langData("Add New Vehicle")}
        </Button>
        <Button variant="outlined" onClick={onClose}>
          {langData("Cancel")}
        </Button>
      </div>
      {addVehiclePopup && (
        <ProfileAddTractorModel
          open={addVehiclePopup}
          disableBrand
          disableModel
          inputs={{
            tractor_brand_id: tractor_list[0].TractorModel.TractorBrand.id,
            tractor_model_id: tractor_list[0].tractor_model_id,
            reg_no: "",
            purchase_year: null,
            manufacture_year: null,
            hours_used: "",
          }}
          onClose={handlePopUpClose}
          onSuccess={onClose}
        />
      )}
    </Dialog>
  );
}

function LoadingServiceCard() {
  return (
    <div className="service-card">
      <div className="image-container">
        <Skeleton variant="circular" width={60} height={60} />
      </div>
      <h3 className="card-title">
        <Skeleton variant="text" />
      </h3>
      <p className="card-subtitle">
        <Skeleton variant="text" />
      </p>
      <span className="tag">
        <div className="icon">
          <IconTimeout />
        </div>
        <div className="text">
          <Skeleton variant="text" width={60} />
        </div>
      </span>
      <div className="task-list">
        {[1, 2, 3, 4].map((no) => (
          <Fragment key={no}>
            <div>
              <IconCircleCheck />
            </div>
            <p>
              <Skeleton variant="text" />
            </p>
          </Fragment>
        ))}
      </div>
      <div className="card-btn">
        <Button variant="contained" disabled>
          + Select Tractor
        </Button>
      </div>
    </div>
  );
}

function NoService() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <img
        src="icons/no-service.svg"
        alt="no-service"
        style={{
          marginTop: "4rem",
          maxWidth: "50vw",
          minWidth: "400px",
          maxHeight: "50vh",
          flexShrink: 0,
        }}
      />
      {/* <h3>No Service Avaliable</h3> */}
      {/* <p>Please try using another Tractor model</p> */}
    </div>
  );
}
