import { Avatar, Button, Menu, MenuItem, } from "@mui/material";
import IconLogoText from "../../../assets/icons/icon-sidebar/icon-logo-text";
import IconLogoTractor from "../../../assets/icons/icon-sidebar/icon-logo-tractor";
import { MenuOnly } from "../header";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import IconDashCustomer from "../../../assets/icons/icon-dashboard/icon-dash-customer";
import IconBookings from "../../../assets/icons/icon-header/icon-bookings";
import { useAuthContext } from "../../../context/authContext";
import IconLogout from "../../../assets/icons/icon-header/icon-logout";
import IconStar from "../../../assets/icons/icon-common/icon-star";
import { useUpdateLanguage } from "./header-api";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { useLanguage } from "../../../language/useLanguage";


export default function HeaderWeb({
  HEADER_MENU,
  languageOptions,
  isImage,
  image,
}) {
  const navigate = useNavigate();
  const updateLanguage = useUpdateLanguage();
  const { getData, setToken } = useAuthContext();
  const { isAuth, userData, lang } = getData();
  const [anchorEl, setAnchorEl] = useState(null);
  const [languageSelect, setLanguageSelect] = useState();
  // const [selectedLanguageId, setSelectedLanguageId] = useState(null);
  const langData = useLanguage();

  const languageMenuOpen = Boolean(languageSelect)
  const handleLanguageDrawerOpen = (event) => setLanguageSelect(event.currentTarget);
  const handleLanguageDrawerClose = () => setLanguageSelect(null);

  const handleClick = (option) => {
    if (isAuth) {
      const form = new FormData();
      form.append("language", option);
      updateLanguage.mutate(form, {
        onSuccess: (response) => {
          setToken(response.data.token);
          setLanguageSelect(null)
        },
      });
    } else {
      setToken("changeLang", option);
      setLanguageSelect(null)
    }
  };

  const open = Boolean(anchorEl);
  const handleDrawerOpen = (event) => setAnchorEl(event.currentTarget);
  const handleDrawerClose = () => setAnchorEl(null);

  const handleNavigation = (path) => {
    handleDrawerClose();
    navigate(path);
  }
  // console.debug({ isAuth, userData, lang })

  const handleLanguageSelect = () => {
    handleLanguageDrawerClose();
  };

  const selectedLanguage = languageOptions.find((option) => option.id === lang);
  // const buttonText = selectedLanguage ? selectedLanguage.label : "English";

  return (
    <div className='header-home'>
      <div className='logo' onClick={() => navigate(`/`)}>
        <IconLogoTractor width='50' height='50' />
        <IconLogoText width='120' height='50' />
      </div>
      <div className="header-menu">
        {HEADER_MENU.map((item, index) =>
          <MenuOnly key={index} item={item} />
        )}
      </div>
      <div className="header-profile-container">
        <div className="language">
          <div className="header-username" >
            <Button
              variant="outlined"
              onClick={languageMenuOpen ? handleLanguageDrawerClose : handleLanguageDrawerOpen}
              startIcon={<IconStar className='start-icon-language' style={{ marginLeft: 4 }} />}
              endIcon={<ArrowDropDownIcon />}
            >{selectedLanguage.label}
            </Button>
          </div>
          <Menu
            open={languageMenuOpen}
            anchorEl={languageSelect}
            className='profile-menu language-menu'
            onClose={handleLanguageDrawerClose}
            elevation={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: "8px",
                boxShadow: "0px 8px 24px 0px rgba(149, 157, 165, 0.37)",
                marginTop: "10px",
                minWidth: "124px",
              },
              '& ul': {
                paddingTop: 0,
                paddingBottom: 0
              },
              '& li': {
                gap: "1em",
                paddingTop: "0.5em",
                paddingBottom: "0.5em",
                alignItems: 'center',
              },
            }}
          >
            {languageOptions.map((option) => (
              <MenuItem key={option.id} className={`language-option ${option.id === lang ? "active" : ""}`} onClick={() => { handleClick(option.id), handleLanguageSelect() }}>
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
        {isAuth ? (
          <div className="profile">
            {isImage ? (
              <Avatar src={image} />
            ) : (
              <Avatar style={{ backgroundColor: 'var(--clr-primary)' }}>{image}</Avatar>
            )}
            <div className="header-user-details">
              <div className="header-userrole">{langData("Hello")}</div>
              <div className="header-username" onClick={open ? handleDrawerClose : handleDrawerOpen}>{userData.first_name}</div>
              <Menu
                open={open}
                className='profile-menu'
                onClose={handleDrawerClose}
                elevation={0}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    borderRadius: "8px",
                    boxShadow: "0px 8px 24px 0px rgba(149, 157, 165, 0.37)",
                    marginTop: "10px"
                  },
                  '& ul': {
                    paddingTop: 0,
                    paddingBottom: 0
                  },
                  '& li': {
                    gap: "1em",
                    paddingTop: "0.5em",
                    paddingBottom: "0.5em",
                    alignItems: 'center',
                  },
                }}
              >
                <MenuItem onClick={() => handleNavigation("/profile")}>
                  <div className="menu-icon">
                    <IconDashCustomer width='2em' height='2em' />
                  </div>
                  <div className="menu-details">
                    <div className="menu-text-header">{langData("My Profile")}</div>
                    <div className="menu-text-subheader">{langData("Manage your Profile, Address & Tractor")}</div>
                  </div>
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("/booking")}>
                  <div className="menu-icon">
                    <IconBookings width='2em' height='2em' />
                  </div>
                  <div className="menu-details">
                    <div className="menu-text-header">{langData("My Booking")}</div>
                    <div className="menu-text-subheader">{langData("See your booking details")}</div>
                  </div>
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("/logout")}>
                  <div className="menu-icon">
                    <IconLogout width='2em' height='2em' />
                  </div>
                  <div className="menu-details">
                    <div className="menu-text-header">{langData("Logout")}</div>
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </div>
        ) : (
          <Button variant='outlined' onClick={() => navigate("/login")}>{langData("Login")}</Button>
        )}
      </div>
    </div>
  )
}