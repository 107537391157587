import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import FormSendOTP from "./login-form/form-sendOTP";
import FormVerifyOTP from "./login-form/form-verifyOTP";
import FormProfile from "./login-form/form-profile";
import { useAuthContext } from "../../context/authContext";

export default function Login() {
  const { getData } = useAuthContext();
  const data = getData();
  const navigate = useNavigate();
  // console.debug("LOGIN: ", data)
  const [step, setStep] = useState(1);
  const [phoneNo, setPhoneNo] = useState("");
  const [tempToken, setTempToken] = useState("");

  function sendSuccess(phone_no) {
    setPhoneNo(phone_no);
    setStep(2);
  }

  function getProfileForm(token) {
    setTempToken(token);
    setStep(3);
  }

  function resendError() {
    setStep(1);
  }

  if (data.isAuth) return <Navigate to="/" />

  return (
    <main className="login-page">
      <div className="brand-title-container">
        <h3>Get to know your<br />Tractor in Detail</h3>
        <div className="blue-line"></div>
        <h3 className="with-us">With Us.</h3>
        <img src="images/tractor-login-page.png" alt="tractor-image" />
      </div>
      <div className="login-form-container">
        <img src="logo/ts-logo-full-color.svg" alt="ts-logo" style={{ cursor: "pointer" }} onClick={() => navigate("/")} />
        {step === 1 && <FormSendOTP sendSuccess={sendSuccess} />}
        {step === 2 && <FormVerifyOTP phone_no={phoneNo} getProfileForm={getProfileForm} resendError={resendError} />}
        {step === 3 && <FormProfile phone_no={phoneNo} tempToken={tempToken} />}
      </div>
    </main>
  )
}
