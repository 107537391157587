import { useLocation } from "react-router"
import { LazyImage } from "../../../assets/image/lazyImage";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete, Avatar, Button, Checkbox, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import InputNumber from "../../../assets/custom-fields/input-number";
import { Regex_email } from "../../../config/validation/regex";
import { useGetDistricts, useGetStates, useGetSubDistricts } from "../../profile/profile-api";
import { useAddExtendWarrantyRequest, useGetWorkshopList } from "../warrenty-api";
import { useRef } from "react";
import UploadDoc from "../../../assets/uploadDoc/uploadDoc";
import { useAuthContext } from "../../../context/authContext";
import IconTrusted from "../../../assets/icons/icon-common/icon-trusted";
import { useLanguage } from "../../../language/useLanguage";

export default function FormWarrantyPurchase() {
  const location = useLocation();
  const { getData } = useAuthContext();
  const { userData } = getData();
  const langData = useLanguage();
  const {
    customer_name,
    phone_no,
    model_img,
    tractor_name,
    tractor_model_id,
    // feed_pump_manufacture_date,
    purchase_year,
    product: {
      id,
      name,
      description,
      price
    },
  } = location.state;
  const descriptionArr = description.split(",");

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      customer_id: "",
      product_id: id,

      // Tractor Owner Details
      owner_name: customer_name,
      owner_phone_no: phone_no,
      owner_email: userData.email ?? "",
      // company_name: "",
      address_1: "",
      address_2: "",
      state_id: null,
      district_id: null,
      sub_district_id: null,
      pincode: "",

      // Tractor Details
      tractor_model_id: tractor_model_id,
      purchase_year: purchase_year,
      engine_no: "",
      chasis_no: "",
      // hours_used: "",
      // manufacture_year: null,

      // Pump Details
      pump_part_no: "",
      pump_si_no: "",
      // feed_pump_manufacture_date: feed_pump_manufacture_date,
      referred: "Yes",
      workshop_id: null,
      pump_nameplate_img: [],
      feed_pump_nameplate_img: [],

      acceptTnc: false,
    },
  })
  const [state_id, district_id, referred] = watch(["state_id", "district_id", "referred"]);
  const dropdownStateList = useGetStates();
  const dropdownDistrictList = useGetDistricts(state_id);
  const dropdownSubdistrictList = useGetSubDistricts(district_id);
  const dropdownWorkshopList = useGetWorkshopList();
  const addRequest = useAddExtendWarrantyRequest();
  // const getYears = getLastYears(30);

  const CC_FORM = useRef();

  const submit = (inputs) => {
    console.debug(inputs);
    const form = new FormData();
    for (const key in inputs) {
      if (key === "pump_nameplate_img" || key === "feed_pump_nameplate_img") {
        inputs[key].forEach(img => form.append(key, img))
      } else {
        form.append(key, inputs[key])
      }
    }

    addRequest.mutate(form, {
      onSuccess: (response) => {
        // debugger;
        console.debug("RESPONSE: ", response);
        const paymentRequest = response.data?.payment;
        CC_FORM.current.action = paymentRequest?.action_url ?? "https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction";
        CC_FORM.current[0].value = paymentRequest?.encRequest;
        CC_FORM.current[1].value = paymentRequest?.access_code;
        CC_FORM.current.submit();
      },
    })
  }

  return (
    <div className="ew-purchase-section">

      <form className="form-warranty-purchase" onSubmit={handleSubmit(submit)}>
        <div className="form-section">
          <h2>Tractor Owner Details</h2>

          <div className="divide-two">
            <div className="field-container">
              <label className="required">Name</label>
              <TextField
                error={!!errors.owner_name}
                {...register("owner_name", {
                  required: langData("Please Enter Name"),
                  minLength: { value: 2, message: "Atleast two character require" },
                  maxLength: { value: 30, message: "Limit exceed, 30 character allowed" },
                })}
                inputProps={{ maxLength: 30 }}
              />
              <p className="form-error-text">{errors.owner_name?.message}</p>
            </div>
            <div className="field-container">
              <label className="required">{langData("Contact Number")}</label>
              <Controller
                name="owner_phone_no"
                control={control}
                rules={{
                  required: langData("Please Enter Contact Number"),
                  maxLength: { value: 10, message: "Enter valid input" },
                  minLength: { value: 10, message: "Enter valid input" },
                }}
                render={({ field }) => (
                  <InputNumber
                    {...field}
                    error={!!errors.owner_phone_no?.message}
                    inputProps={{ maxLength: 10 }}
                  />
                )}
              />
              <p className="form-error-text">{errors.owner_phone_no?.message}</p>
            </div>
          </div>

          <div className="divide-two">
            <div className="field-container">
              <label className="required">Email ID</label>
              <TextField
                error={!!errors.owner_email}
                {...register("owner_email", {
                  required: "Please Email ID",
                  pattern: Regex_email,
                  minLength: { value: 5, message: "Atleast five character require" },
                  maxLength: { value: 50, message: "Limit exceed, 50 character allowed" },
                })}
                inputProps={{ maxLength: 50 }}
              />
              <p className="form-error-text">{errors.owner_email?.message}</p>
            </div>
            {/* <div className="field-container">
              <label className="required">Company Name</label>
              <TextField
                error={!!errors.company_name}
                {...register("company_name", {
                  required: "Please Enter Company Name",
                  minLength: { value: 2, message: "Atleast two character require" },
                  maxLength: { value: 50, message: "Limit exceed, 50 character allowed" },
                })}
                inputProps={{ maxLength: 50 }}
              />
              <p className="form-error-text">{errors.company_name?.message}</p>
            </div> */}
          </div>

          <div className="field-container">
            <label className="required">Address Line 1</label>
            <TextField
              error={!!errors.address_1}
              {...register("address_1", {
                required: "Please Enter Address",
                minLength: { value: 2, message: "Atleast two character require" },
                maxLength: { value: 200, message: "Limit exceed, 200 character allowed" },
              })}
              inputProps={{ maxLength: 200 }}
            />
            <p className="form-error-text">{errors.address_1?.message}</p>
          </div>

          <div className="field-container">
            <label>Address Line 2</label>
            <TextField
              error={!!errors.address_2}
              {...register("address_2", {
                minLength: { value: 2, message: "Atleast two character require" },
                maxLength: { value: 200, message: "Limit exceed, 200 character allowed" },
              })}
              inputProps={{ maxLength: 200 }}
            />
            <p className="form-error-text">{errors.address_2?.message}</p>
          </div>

          <div className="divide-two">
            <div className="field-container">
              <label className="required">State</label>
              <Controller
                name="state_id"
                control={control}
                rules={{ required: langData("Please Select State") }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={dropdownStateList.data ?? []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // placeholder="State"
                          error={!!errors.state_id}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value
                      }
                      getOptionLabel={(option) =>
                        option.label ??
                        dropdownStateList.data?.find((opt) => opt.id === option)
                          ?.label ??
                        ""
                      }
                      onChange={(__event, data) => {
                        field.onChange(data?.id ?? null);
                        setValue(`district_id`, null);
                        setValue(`sub_district_id`, null);
                      }}
                    />
                  );
                }}
              />
              <p className="form-error-text">{errors.state_id?.message}</p>
            </div>
            <div className="field-container">
              <label className="required">District</label>
              <Controller
                name="district_id"
                control={control}
                rules={{ required: langData("Please Select District") }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      disabled={!state_id}
                      options={dropdownDistrictList.data ?? []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // placeholder="District"
                          error={!!errors.district_id}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value
                      }
                      getOptionLabel={(option) =>
                        option.label ??
                        dropdownDistrictList.data?.find(
                          (opt) => opt.id === option
                        )?.label ??
                        ""
                      }
                      onChange={(__event, data) => {
                        field.onChange(data?.id ?? null);
                        setValue("sub_district_id", null);
                      }}
                    />
                  );
                }}
              />
              <p className="form-error-text">{errors.district_id?.message}</p>
            </div>
          </div>

          <div className="divide-two">
            <div className="field-container">
              <label className="required">Tehsil</label>
              <Controller
                name="sub_district_id"
                control={control}
                rules={{ required: langData("Please Select Tehsil") }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      disabled={!district_id}
                      options={dropdownSubdistrictList.data ?? []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // placeholder="Tehsil"
                          error={!!errors.sub_district_id}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value
                      }
                      getOptionLabel={(option) =>
                        option.label ??
                        dropdownSubdistrictList.data?.find(
                          (opt) => opt.id === option
                        )?.label ??
                        ""
                      }
                      onChange={(__event, data) => {
                        field.onChange(data?.id ?? null);
                      }}
                    />
                  );
                }}
              />
              <p className="form-error-text">{errors.sub_district_id?.message}</p>
            </div>
            <div className="field-container">
              <label className="required">Pincode</label>
              <Controller
                name="pincode"
                control={control}
                rules={{
                  required: "Please Enter Pincode",
                  minLength: { value: 1, message: "Enter valid input" },
                  maxLength: { value: 6, message: "Enter valid input" },
                }}
                render={({ field }) => (
                  <InputNumber
                    {...field}
                    error={!!errors.pincode?.message}
                    inputProps={{ maxLength: 6 }}
                  />
                )}
              />
              <p className="form-error-text">{errors.pincode?.message}</p>
            </div>
          </div>
        </div>
        <div className="form-section">
          <h2>Tractor Details</h2>

          <div className="divide-two">
            <div className="field-container">
              <label className="required">Engine No</label>
              <TextField
                error={!!errors.engine_no}
                {...register("engine_no", {
                  required: "Please Enter Engine Number",
                  minLength: { value: 2, message: "Atleast two character require" },
                  maxLength: { value: 30, message: "Limit exceed, 30 character allowed" },
                })}
                inputProps={{ maxLength: 30 }}
              />
              <p className="form-error-text">{errors.engine_no?.message}</p>
            </div>
            <div className="field-container">
              <label className="required">Chasis No</label>
              <TextField
                error={!!errors.chasis_no}
                {...register("chasis_no", {
                  required: "Please Enter Chasis Number",
                  minLength: { value: 2, message: "Atleast two character require" },
                  maxLength: { value: 30, message: "Limit exceed, 30 character allowed" },
                })}
                inputProps={{ maxLength: 30 }}
              />
              <p className="form-error-text">{errors.chasis_no?.message}</p>
            </div>
          </div>

          {/* <div className="divide-two">
            <div className="field-container">
              <label className="required">Used Hours</label>
              <Controller
                name="hours_used"
                control={control}
                rules={{
                  required: "Please Enter Used Hours",
                  minLength: { value: 1, message: "Enter valid input" },
                  maxLength: { value: 5, message: "Enter valid input" },
                }}
                render={({ field }) => (
                  <InputNumber
                    {...field}
                    error={!!errors.hours_used?.message}
                    inputProps={{ maxLength: 5 }}
                  />
                )}
              />
              <p className="form-error-text">{errors.hours_used?.message}</p>
            </div>
            <div className="field-container">
              <label className="required">Year of Manufacture</label>
              <Controller
                name="manufacture_year"
                control={control}
                rules={{
                  required: "Please Select Year of Manufacture"
                }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={getYears}
                      value={field.value?.toString() ?? null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // placeholder={dayjs().format("YYYY")}
                          error={Boolean(errors.manufacture_year)}
                        />
                      )}
                      onChange={(___event, newValue) => {
                        field.onChange(newValue ? Number(newValue) : newValue)
                      }}
                    />
                  )
                }}
              />
              <p className="form-error-text">{errors.manufacture_year?.message}</p>
            </div>
          </div> */}
        </div>

        <div className="form-section">
          <h2>Pump Details</h2>

          <div className="divide-two">
            <div className="field-container">
              <label className="required">Pump Part No</label>
              <TextField
                error={!!errors.pump_part_no}
                {...register("pump_part_no", {
                  required: "Please Enter Pump Part Number",
                  minLength: { value: 2, message: "Atleast two character require" },
                  maxLength: { value: 30, message: "Limit exceed, 30 character allowed" },
                })}
                inputProps={{ maxLength: 30 }}
              />
              <p className="form-error-text">{errors.pump_part_no?.message}</p>
            </div>
            <div className="field-container">
              <label className="required">Pump SI No</label>
              <TextField
                error={!!errors.pump_si_no}
                {...register("pump_si_no", {
                  required: "Please Enter Pump SI Number",
                  minLength: { value: 2, message: "Atleast two character require" },
                  maxLength: { value: 30, message: "Limit exceed, 30 character allowed" },
                })}
                inputProps={{ maxLength: 30 }}
              />
              <p className="form-error-text">{errors.pump_si_no?.message}</p>
            </div>
          </div>

          <div className="divide-two">
            <div className="field-container form-upload-container">
              {/* <label>Upload Pump Nameplate Photo</label> */}
              <label className="required">Tractor Manufacturing Nameplate Photo</label>
              <Controller
                name="pump_nameplate_img"
                control={control}
                rules={{
                  required: "Please Upload Tractor Manufacturing Nameplate",
                  validate: {
                    isEmpty: (value) => value.length === 0 ? "Please Upload Tractor Manufacturing Nameplate Photo" : true
                  }
                }}
                render={({ field }) => {
                  return (
                    <UploadDoc
                      files={field.value}
                      name="pump_nameplate_img"
                      onUpload={(files) => field.onChange([...files])}
                      onRemove={() => field.onChange([])}
                      isError={Boolean(errors.pump_nameplate_img)}
                      acceptedFileFormat={["jpg", "jpeg", "png"]}
                      disableCircleImg
                    />
                  )
                }}
              />
              <p className="form-error-text">{errors.pump_nameplate_img?.message}</p>
            </div>
            <div className="field-container form-upload-container">
              <label className="required">Pump Nameplate Photo</label>
              <Controller
                name="feed_pump_nameplate_img"
                control={control}
                rules={{
                  required: "Please Upload Pump Nameplate",
                  validate: {
                    isEmpty: (value) => value.length === 0 ? "Please Upload Pump Nameplate Photo" : true
                  }
                }}
                render={({ field }) => {
                  return (
                    <UploadDoc
                      files={field.value}
                      name="feed_pump_nameplate_img"
                      onUpload={(files) => field.onChange([...files])}
                      onRemove={() => field.onChange([])}
                      isError={Boolean(errors.feed_pump_nameplate_img)}
                      // setError={(msg) => handleImgError("pump_nameplate_img", msg)}
                      acceptedFileFormat={["jpg", "jpeg", "png"]}
                      disableCircleImg
                    />
                  )
                }}
              />
              <p className="form-error-text">{errors.feed_pump_nameplate_img?.message}</p>
            </div>
          </div>

          <div className="divide-two">
            <div className="field-container">
              <label className="required">Do You Have Referred Through Tractor Seva Franchise?</label>
              <Controller
                rules={{
                  required: "Select Applicable Option",
                  onChange: (event) => { event.target.value === "No" && setValue("workshop_id", null) },
                  deps: ["workshop_id"],
                }}
                control={control}
                name="referred"
                render={(props) => {
                  return (
                    <RadioGroup row {...props.field}>
                      <FormControlLabel style={{ marginBottom: 8, marginTop: -8 }} value="Yes" control={<Radio color="secondary" />} label="Yes" />
                      <FormControlLabel style={{ marginBottom: 8, marginTop: -8 }} value="No" control={<Radio color="secondary" />} label="No" />
                    </RadioGroup>
                  )
                }}
              />
              <p className="form-error-text">{errors.referred?.message}</p>
            </div>

            {referred === "Yes" && <div className="field-container">
              <label className={referred === "Yes" ? "required" : ""}>Select Tractor Seva Franchise</label>
              <Controller
                name="workshop_id"
                control={control}
                rules={{
                  validate: {
                    checkReferred: (value, formField) => formField.referred === "Yes" && !value ? "Please Select Tractor Seva Franchise" : true
                  }
                }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={dropdownWorkshopList.data ?? []}
                      disabled={!(referred === "Yes")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.workshop_id)}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value
                      }
                      getOptionLabel={(option) =>
                        option.label ??
                        dropdownWorkshopList.data?.find((opt) => opt.id === option)
                          ?.label ??
                        ""
                      }
                      onChange={(__event, data) => field.onChange(data?.id ?? null)}
                    />
                  );
                }}
              />
              <p className="form-error-text">{errors.workshop_id?.message}</p>
            </div>}
          </div>

          {/* <div className="divide-two">
            <div className="field-container">
              <label className={referred === "Yes" ? "required" : ""}>Select Tractor Seva Franchise</label>
              <Controller
                name="workshop_id"
                control={control}
                rules={{
                  validate: {
                    checkReferred: (value, formField) => formField.referred === "Yes" && !value ? "Please Select Tractor Seva Franchise" : true
                  }
                }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={dropdownWorkshopList.data ?? []}
                      disabled={!(referred === "Yes")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.workshop_id)}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value
                      }
                      getOptionLabel={(option) =>
                        option.label ??
                        dropdownWorkshopList.data?.find((opt) => opt.id === option)
                          ?.label ??
                        ""
                      }
                      onChange={(__event, data) => field.onChange(data?.id ?? null)}
                    />
                  );
                }}
              />
              <p className="form-error-text">{errors.workshop_id?.message}</p>
            </div>
            <div></div>
          </div> */}

          <div className="divide-two">
            <div className="field-container">
              <Controller
                rules={{
                  required: "Please Accept Terms & Conditions",
                }}
                control={control}
                name="acceptTnc"
                render={(props) => {
                  return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        disableRipple
                        color="secondary"
                        checked={props.field.value}
                        onChange={(__event, checked) => { props.field.onChange(checked) }}
                        sx={{ pl: 0, py: 0 }}
                      />
                      <p
                        onClick={() => props.field.onChange(!props.field.value)}
                        style={{ cursor: "pointer", marginRight: 4 }}
                      >
                        I Agree to the
                      </p>
                      <a id="warranty-bosch-tnc" href="/warranty-bosch-t&c" target="_blank">Terms & Conditions</a>
                    </div>
                  )
                }}
              />
              <p className="form-error-text">{errors.acceptTnc?.message}</p>
            </div>
          </div>

        </div>
        <div>
          <Button variant="contained" type="submit" sx={{ mt: 2 }} disabled={addRequest.isLoading}>Submit</Button>
        </div>
      </form>

      <div className="warranty-mini-summary summary-card">
        <div className="name">Hi {customer_name},</div>
        <div className="tractor-details">
          <Avatar sx={{ width: 80, height: 80 }}>
            <LazyImage name={model_img} fallbackImage="tractor-model-image.svg" width={80} height={80} />
          </Avatar>
          <div className="details-section">
            <p>{tractor_name}</p>
            <div>
              <div className="details-block">
                <div>Year of Purchase</div>
                <p>{purchase_year}</p>
              </div>
              <div className="details-block">
                <div>Product</div>
                <p>{name}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="purchase-card">
          <div className="name-title"><IconTrusted />{name} warranty</div>
          <ul className="description-list">
            {descriptionArr.map(ele => <li key={ele} style={{ marginBottom: 8 }}>{ele}</li>)}
          </ul>
          <div className="purchase-action">
            <div className="price">
              <p>Pricing</p>
              <div style={{ color: "var(--clr-secondary)", textAlign: "end", fontSize: "1.25rem", fontWeight: 700 }}>
                ₹ {price}
                <div style={{ color: "var(--clr-gray)", fontSize: 12, fontWeight: 500 }}>(Including GST)</div>
              </div>
            </div>
          </div>
          {/* <div style={{ paddingTop: 0, fontSize: 12, color: "var(--clr-gray)" }} >
            Kindly note that the approval/rejection of your warranty claim for {name} may take a maximum of 30 days. We appreciate your patience and understanding.
          </div> */}
        </div>
        <div style={{ padding: "0px 0.5rem", fontSize: 12, color: "var(--clr-gray)" }} >
          <b>Note :</b> Approval/Rejection of your warranty claim for {name} may take a maximum of 30 days. We appreciate your patience and understanding.
        </div>
      </div>

      <form
        hidden
        id="nonseamless"
        ref={CC_FORM}
        method="post"
        name="redirect"
      // action={paymentRequest?.action_url ?? "https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"}
      >
        <input
          type="hidden"
          id="encRequest"
          name="encRequest"
        // value={paymentRequest?.encRequest} 
        />
        <input
          type="hidden"
          name="access_code"
          id="access_code"
        // value={paymentRequest?.access_code} 
        />
      </form>

    </div>
  )
}