export default function IconUser({
  width = "1em",
  height = "1em",
  color = "var(--clr-primary)",
  color2 = "var(--clr-secondary)",
  ...props
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18" fill="none" {...props}>
      <path d="M9 8C6.79086 8 5 6.20914 5 4C5 1.79086 6.79086 0 9 0C11.2091 0 13 1.79086 13 4C13 6.20914 11.2091 8 9 8Z" fill={color} />
      <path d="M0.000651684 17.1992C0.388259 12.4265 4.26191 10 8.98334 10C13.7712 10 17.7049 12.2932 17.9979 17.2C18.0096 17.3955 17.9979 18 17.2467 18C13.5411 18 8.03472 18 0.727502 18C0.476712 18 -0.0204618 17.4592 0.000651684 17.1992Z" fill={color2} />
    </svg>
  )
}
