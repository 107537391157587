import { Avatar, Button } from "@mui/material";
import { useBookingContext } from "./booking-add";
import FormAddressPreference from "./booking-form/form-address-preference";
import { useFormContext } from "react-hook-form";
import FormWorkshop from "./booking-form/form-workhsop";
import FormDateTime from "./booking-form/form-date-time";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import { LazyImage } from "../../assets/image/lazyImage";
import { useLanguage } from "../../language/useLanguage";

export default function AddBookingForm() {
  const { step, stepNext, stepPrev, bookingDataSummary, booking_meta_data } = useBookingContext();
  const { trigger, formState, watch } = useFormContext();
  const { isSubmitting } = formState;
  const navigate = useNavigate();
  const location = useLocation();
  const langData = useLanguage();
  // const [selectData, setSelectData] = useState(null);
  // console.debug({ step });
  // console.debug({ bookingDataSummary });
  // console.debug({ booking_meta_data });

  async function submit() {
    // console.debug({ step });
    let trigger_data;
    if (step === 0) {
      trigger_data = ["customer_address_id"];
    } else if (step === 1) {
      trigger_data = ["workshop_id"];
    }
    const data = await trigger(trigger_data);
    const [formValue] = watch(trigger_data)
    if (!data || !formValue) {
      return;
    }
    stepNext();
  }

  function previous() {
    if (step === 0) {
      navigate('/', {
        state: {
          model_id: booking_meta_data.tractor_model_id,
          tractor_brand_id: location.state.tractor_brand_id
        }
      })
    } else {
      stepPrev();
    }

  }

  function handleFinalSubmit() {
    const button = document.getElementById("submit-booking");
    button.click();
  }


  return (
    <div className="booking-form-container">
      <div className="booking-form">
        <CurrentForm step={step} />
      </div>

      <div className="booking-meta">
        <div>
          <h2>{langData("Summary")}</h2>
          {Object.keys(booking_meta_data).length > 0 && (
            <div className="booking-seletion">
              <div className="tractor">
                <Avatar className="icon-container">
                  <LazyImage name={booking_meta_data.tractor_model.model_img} width={50} height={50} fallbackImage="tractor-model-image.svg" />
                </Avatar>
                {/* <p className="title">{langData("Selected Tractor")}</p>
                <p className="details">{booking_meta_data.tractor_model.label}</p> */}
              </div>
              <div className="service">
                <p className="title">{langData("Selected Service")}</p>
                <div className="service-details">
                  <p className="details">{langData(booking_meta_data.service.service_type_name)} - {langData(booking_meta_data.tractor_model.label)}</p>
                  <p className="details">₹ {booking_meta_data.service.Items[0].Service.offer_total}</p>
                </div>
              </div>
              {bookingDataSummary.map((obj) => obj)}
            </div>
          )}
        </div>
      </div>

      <div className="cart cart-booking">
        <div className="cart-details">
          <Avatar className="icon-container">
            <LazyImage name={booking_meta_data.tractor_model.model_img} width={50} height={50} fallbackImage="tractor-model-image.svg" />
          </Avatar>
          {/* <div className="tractor-details">
            <p className="title">{langData("Selected Tractor")}</p>
            <p className="details">{booking_meta_data.tractor_model.label}</p>
          </div>
          <div style={{ width: 1, backgroundColor: "#FFFFFF4B" }}></div> */}
          <div className="service-details">
            <p className="title">{langData("Selected Service")}</p>
            <p className="details">{langData(booking_meta_data.service.service_type_name)} - {langData(booking_meta_data.tractor_model.label)}</p>
            <p className="details">
              ₹ {booking_meta_data.service.Items[0].Service.offer_total}
            </p>
          </div>
        </div>
        <div className="btn-container">
          <Button
            variant="outlined"
            color="white"
            onClick={previous}
            disabled={isSubmitting}
          >
            {langData("Previous")}
          </Button>
          {step === 2 ? (
            <Button
              variant="contained"
              color="white"
              onClick={handleFinalSubmit}
              disabled={isSubmitting}
            >
              {langData("Submit")}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="white"
              onClick={submit}
              disabled={isSubmitting}
            >
              {langData("Next")}
            </Button>
          )

          }
        </div>
      </div>
    </div>
  )
}

const CurrentForm = ({ step }) => {
  switch (step) {
    case 0:
      return <FormAddressPreference />;
    case 1:
      return <FormWorkshop />;
    case 2:
      return <FormDateTime />;
    default:
      return <p>Please Select Step</p>
  }
}