import "./franchise-faqs.css";
import * as React from "react";
import { styled } from "@mui/material/styles";
// import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
// import IconArrowDown from "../../../assets/icons/icon-common/icon-arrow-down";
import IconArrowDown from "../../../../../assets/icons/icon-common/icon-arrow-down";
import { useLanguage } from "../../../../../language/useLanguage";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<IconArrowDown style={{ transform: "rotate(270deg)" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function FranchiseFaqs() {
  //   const [expanded, setExpanded] =
  //     (React.useState < string) | (false > "panel1");
  const langData = useLanguage();
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="faqs-container">
      <div className="faqs-heading-container">
        <h5>{langData(`Quick Answers`)}</h5>
        <p>{langData(`Frequently asked Questions`)}</p>
      </div>
      <div className="accordions-container">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            className="test-accr"
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography>
              {langData(`What are the requirements to become a tractor seva franchise`)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {langData("We look for individuals with a passion for the industry, adequate financial resources, and a commitment to uphold our brand's values and standards.")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>
              {langData("In prior experience in the tractor service industry necessary to become a franchisee ?")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {langData("While prior experience in the industry is beneficial, it&#39;s not mandatory. Our comprehensive training program will equip you with the knowledge and skills you need.")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>
              {langData("What territories are available for franchising ?")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {langData("Currently, we are actively seeking franchisees for Tractor Seva in Maharashtra and Madhya Pradesh. We have plans to expand to various regions and are open to exploring new territories.")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
            <Typography>
              {langData("How long does it take to start a tractor seva franchise ?")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {langData("The timeline varies, but on average, it takes 7 to 10 working days from the initial inquiry to launch. The exact duration will depend on various factors, including location and permits.")}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
